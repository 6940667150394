export const AnimeSpecExample: AnimeSpec = {
  name: "First Prototype",
  items: [
    { 
      elementType: "image",
      image: { url: "data/screentest.png" }
    }, 
    {
      elementType: "rectangle",
      position: { left: 50, vleft: 1, visiblePeriods: [[3, 5]] },
      layout: { length: 0 },
    },
    {
      elementType: "text",
      position: { left: 100, vleft: 0 },
      textDisplay: { length: 0 },
    }
  ]
}

export type AnimeSpec = {
  name: string,
  items: Element[]
}

// Element & Specific Element Types --------------------------------------------
export type Element = Image | Rectangle | Text

export type Image = {
  elementType: "image",
  image: ImageProperty
}

export type Rectangle = {
  elementType: "rectangle",
  position: PositionProperty,
  layout: LayoutProperty,
}

export type Text = {
  elementType: "text",
  position: PositionProperty,
  textDisplay: TextDisplayProperty,
}

// Components & Property -------------------------------------------------------
export type Components = {
  elementType: Map<number, ElementType>,
  image?: Map<number, ImageProperty>,
  position?: Map<number, PositionProperty>,
  layout?: Map<number, LayoutProperty>,
  textDisplay?: Map<number, TextDisplayProperty>,
}

export type ElementType = string | null | undefined;

export type ImageProperty = {
  url?: string,
  image?: any,
}

export type PositionProperty = {
  left?: number,
  vleft?: number,
  visiblePeriods?: Period[],
  visible?: boolean,
}

export type LayoutProperty = {
  length?: number,
}

export type TextDisplayProperty = {
  length?: number,
  lastChange?: number
}

export type Period = [number, number | null]
