import { Rect, FabricText } from "fabric";
import Anime from "../Anime"

export async function renderingSystem(anime: Anime, dt: number) {
  anime.canvas.clear();
  for (const entityId of anime.entities) {
    let fabricObject;
    let comp = anime.components;
    
    // Check visibility
    if (
      comp?.position?.get(entityId) && 
      !comp.position.get(entityId)?.visible
    ) continue;

    // Render if needed
    switch (comp.elementType.get(entityId)) {
      case 'image':
        fabricObject = comp?.image?.get(entityId)?.image;
        break;
      case 'rectangle':
        fabricObject = new Rect({
          left: comp?.position?.get(entityId)?.left as number || 0,
          top: 100,
          fill: 'red',
          width: 60,
          height: 60
        });  
        break;
      case 'text':
        fabricObject = new FabricText(
          "1ทดสอบการพิมพ์".substring(0, comp?.textDisplay?.get(entityId)?.length || 0), 
          {left: 100, top: 200, strokeWidth: 2});
        break;
    }
    if (fabricObject) {
        anime.canvas.add(fabricObject);
    }
  }
  anime.canvas.renderAll();
}
  