import ISMORController from './ISMORController'
// import axios from 'axios'
import app from "firebase/compat/app";
import { loadBokehJS, loadPythonWorker } from "ismor-lib/utils/Python";
import moment from "moment";
import * as duckdb from '@duckdb/duckdb-wasm';

// import init, { run } from "wasm-lib";
// async function testwasm() {
//   await init();
//   console.log(run('{"name": "abc1"}'));
// }
// testwasm();

type Handler = (controller: ISMORController, params?: any) => any

const adminUsers = [
  "drjay@thevcgroup.com",
]

export const ISMORDidMount: Handler = async (controller, params) => {

  const onFirebaseAuthStateChanged = async (user: any) => {
    if (user?.email && user?.email?.includes("@thevcgroup.com")) {
      // Check if logged in
      console.log("user: ", user.email);
      if (await checkAdminAuhorizedUser(controller, {user})) {
      // Or proceed to screen
        setLoggedinData(controller);
      } else {
        controller.setState({authChecked: true});
      }
    } else {
      if (user) {
        console.log("not valid user (email, uid)", user?.email, user?.uid);
        controller.setState({authChecked: true});
        logout(controller);
      }
      else {
        console.log("no user");
        controller.setState({authChecked: true});
      }
      refreshUI(controller);
    }
  }
  controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
  refreshUI(controller);

  LoadPython(controller, params);
  LoadBokeh(controller, params);
  LoadDuckDB(controller, params);
  
  // Test load wasm from assemblyscript
  // let result = await WebAssembly.instantiateStreaming(fetch("/as/release.wasm", {}));
  // let {add}: any = result.instance.exports;
  // console.log(add(10, 21));

  controller?.appweb?.auth().onAuthStateChanged(async (user: any) => {
    if (user?.email && user?.email?.includes("@thevcgroup.com")) {
      console.log("webuser:", user.email);
      controller.dbweb = controller.appweb.firestore();
      controller.storageweb = controller.appweb.storage();
      controller.setState({webLoggedin: true});
    }
  });

  if (!controller.ismoradmin) return;
  controller?.appadmin?.auth().onAuthStateChanged(async (user: any) => {
    if (user?.email && user?.email?.includes("@thevcgroup.com")) {
      console.log("adminuser:", user.email);
      if (await checkAdminAuhorizedUser(controller, {user})) {
        console.log("setup firebase objects in controller")
        controller.dbadmin = controller.appadmin.firestore();
        controller.storageadmin = controller.appadmin.storage();
        controller.functionsadmin = controller.appadmin.functions();
        controller.setState({adminLoggedin: true});
      }
    } else {
      if (user) {
        console.log("not valid adminuser (email, uid)", user?.email, user?.uid);
        logout(controller);
      }
      else
        console.log("no adminuser");
    }
  });
}

const LoadPython: Handler = async (controller, params) => {
  const python = await loadPythonWorker("Redmine", 
    (loaded: boolean) => controller.setState({pythonLoaded: loaded}), 
    (error: any) => controller.setState({pythonError: error}), false, `
  #import pandas
  import micropip
  await micropip.install('fastparquet')
  import fastparquet
  #await micropip.install('holoviews')
  #import hvplot.pandas
  #import holoviews as hv
  #from bokeh.plotting import figure
  #from bokeh.embed import json_item
  True`)
  controller.setData({python: python});
}

const LoadBokeh: Handler = async (controller, params) => {
  loadBokehJS((loaded: boolean) => controller.setState({bokehLoaded: loaded}));
}

const LoadDuckDB: Handler = async (controller, params) => {
  try {
    // Old approach - serve duckdb via cdn
    // const JSDELIVR_BUNDLES = duckdb.getJsDelivrBundles();
    // const bundle = await duckdb.selectBundle(JSDELIVR_BUNDLES);
    // const worker_url = URL.createObjectURL(
    //   new Blob([`importScripts("${bundle.mainWorker!}");`], {type: 'text/javascript'})
    // );
    // const worker = new Worker(worker_url);
    // const logger = new duckdb.ConsoleLogger();
    // let duck = new duckdb.AsyncDuckDB(logger, worker);
    // await duck.instantiate(bundle.mainModule, bundle.pthreadWorker);
    // URL.revokeObjectURL(worker_url);

    // Change to statically serve duckdb wasm instead of cdn
    const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
        mvp: {
            mainModule: '/duckdb/duckdb-mvp.wasm',
            mainWorker: '/duckdb/duckdb-browser-mvp.worker.js',
        },
        eh: {
            mainModule: '/duckdb/duckdb-eh.wasm',
            mainWorker: '/duckdb/duckdb-browser-eh.worker.js',
        },
    };
    // Select a bundle based on browser checks
    const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
    // Instantiate the asynchronous version of DuckDB-Wasm
    const worker = new Worker(bundle.mainWorker!);
    const logger = new duckdb.ConsoleLogger();
    const duck = new duckdb.AsyncDuckDB(logger, worker);
    await duck.instantiate(bundle.mainModule, bundle.pthreadWorker);

    let duckCon = await duck.connect();
    controller.setData({duck, duckCon});
    controller.setState({duckLoaded: true});
  } catch (e: any) {
    console.log(e);
  }
}

const checkAdminAuhorizedUser: Handler = async (controller, params) => {
  if (controller.ismoradmin && !(adminUsers.includes(params.user.email))) {
    console.log("User has no right to access this program");
    logout(controller);
    return false
  } else {
    return true
  }
}

export const SavePreferences: Handler = async (controller, params) => {
  if (controller.user && controller.user.email) {
    controller.db.collection("preferences")
    .doc(controller.user.email)
    .update({
      ...params
    });
    controller.setState({
      preferences: {
        ...(controller.getState()?.preferences || {}), 
        ...params
      }});
  }
}

export const logout: Handler = (controller) => {
  clearData(controller);
  controller.appadmin?.auth()?.signOut().catch((error: any) => {
    console.log("signOut Admin error")
    console.log(error);
  });
  controller.app.auth().signOut().then(() => {
    refreshUI(controller);
  }).catch((error: any) => {
    console.log("signOut error")
    console.log(error)
  });
  controller.appweb.auth().signOut().catch((error: any) => {
    console.log("signOut web error");
    console.log(error);
  });
}

export const DownloadApp: Handler = async (controller, params) => {
  const url = await controller.storage
    .refFromURL(`gs://static-hosting-3f3a6.appspot.com/ismorplus/ismorplus.zip`)
    .getDownloadURL();
  const response = await fetch(url)
  const link = document.createElement('a');
  link.download = "ismorplus.zip";
  link.href = URL.createObjectURL(new Blob([await response.blob()], 
    {type: "application/zip"}));
  link.click();
}

const SetupNoti: Handler = async (controller, {permissions, redmineUser}) => {
  controller.data.unsubscribeNoti();
  const handleNoti = (querySnapshot: app.firestore.QuerySnapshot) => {
    let notifications: any = controller.getState().notifications;
    querySnapshot.docChanges().forEach((change: app.firestore.DocumentChange) => {
      console.log(moment().format("YYYYMMDD:HH:mm:ss"), change.doc.id, change.type, 
        change.doc.metadata.hasPendingWrites ? "Local" : "Server");
      notifications[change.doc.id] = change.doc.data();
    });
    controller.setState({notifications: Object.assign({}, notifications)});
  }

  if (permissions?.app?.includes("notitest")) {
    controller.data.unsubscribeNoti = controller.db.collection("notifications")
      .onSnapshot({
        next: handleNoti,
        error: (e: any) => { console.log(e); }
      });
  } else {
    controller.data.unsubscribeNoti = controller.db.collection("notifications")
      .where("__name__", "==", redmineUser)
      .onSnapshot({
        next: handleNoti,
        error: (e: any) => { console.log(e); }
      });
  }
}

export const ClearNotifications: Handler = async (controller, params) => {
  const {notiId, notiData} = params;
  if (!notiId || !notiData) return;
  let newNotiData  = Object.assign({}, notiData);
  for (const issueId in newNotiData) {
    newNotiData[issueId].read = true;
    for (const detailId in newNotiData[issueId].details) {
      newNotiData[issueId].details[detailId].read = true
    }
  }
  controller.db.collection("notifications").doc(notiId).set(newNotiData);
}

export const SetPin: Handler = async (controller, {pin}) => {
  if (!controller.user?.email) return;
  controller.db.collection("preferences")
    .doc(controller.user.email)
    .update({pin})
  controller.setState({
    preferences: {
      ...(controller.getState()?.preferences || {}),
      pin
    }
  });
}

// Helper functions ---------------------------------------------------------------

const refreshUI: Handler = (controller) => {
  if (!(controller.app.auth().currentUser?.email &&
    controller.app.auth().currentUser?.email?.includes("@thevcgroup.com"))
  ) {
    // controller.ui.start('#firebase-login', {
    //   callbacks: {
    //     signInSuccessWithAuthResult: function(authResult, redirectUrl) {
    //       return false;
    //     },
    //   },
    //   signInOptions: [
    //     controller.app.auth.GoogleAuthProvider.PROVIDER_ID,
    //   ],
    // })
  } else {
    setLoggedinData(controller)
  }
}

const setLoggedinData: Handler = async (controller) => {
  if (!(controller.app.auth().currentUser?.email?.includes("@thevcgroup.com"))) {
    console.log("Unauthorized user")
    return;
  }
  const state = controller.getState();
  controller.user = controller.app.auth().currentUser;
  const permissions = (await controller.db.collection("permissions")
    .doc(controller.user.email)
    .get())?.data() || {};
  const preferences = await controller.db.collection("preferences")
    .doc(controller.user.email)
    .get();
  let allXd = (await 
    controller.db.collection("cache").doc("xd2").get())?.data()?.allXd || [];

  controller.setState({
    authChecked: true,
    loggedin: true, 
    permissions: permissions,
    preferences: preferences?.data() || {},
    xdmaster: { allXd }
  });

  if (preferences.exists) {
    const preferencesData = preferences.data();
    let tab: string = "";
    if (state.tab && state.tab !== "") {
      tab = state.tab;
    } else if (state.webviewReady) {
      tab = preferencesData?.plustab || "";
    } else if (controller.ismoradmin) {
      tab = preferencesData?.admintab || "";
    } else {
      tab = preferencesData?.tab || "";
    }
    
    const hn = preferencesData?.hn;
    const hnList = preferencesData?.hnList || [];
    let redmineUser: string;
    if (permissions?.app?.includes("debug")) {
      redmineUser = "drjay@thevcgroup.com";
    } else {
      redmineUser = preferencesData?.redmineUser || controller.user.email;
    }
    controller.setState({ 
      tab, 
      hn,
      hnList,
      redmineUser,
      selectedUser: redmineUser,
    });
    if (permissions?.app?.includes("debug")) {
      // SetupNoti(controller, {permissions, redmineUser});
    }
  } else {
    const prefDefault = { tab: state.tab === "" ? "Redmine" : state.tab }
    controller.setState(prefDefault)
    controller.db.collection("preferences")
    .doc(controller.user.email)
    .set(prefDefault)
  }
}

const clearData: Handler = (controller) => {
  controller.user = null
  controller.setState({
    loggedin: false,
    adminLoggedin: false,
    releases: [],
    pythonLoaded: false,
    pythonError: {},
    bokehLoaded: false,
  })
}

// Uncomment to load python and / or wasm
// controller.handleEvent({ message: "LoadPython", params: {} })

// Test wasm (need to await before executing test script)
// await controller.handleEvent({ message: "LoadWasm", params: {} })
// console.log("wasm: run(5)", controller.wasm.run(5))
// console.log("wasm: process_string('hello')", controller.wasm.process_string('hello'))

// Load wasm for real (no need to await)
// controller.handleEvent({ message: "LoadWasm", params: {} })

// LoadSAPMAP(controller)

export const LoadWasm: Handler = async (controller) => {
    // try {
    //     const wasm = await import('wasm-lib');
    //     controller.wasm = wasm;
    // } catch(err) {
    //     console.error(`Unexpected error in loadWasm. [Message: ${err.message}]`);
    // }
}

export const LoadSAPMAP: Handler = async (controller) => {
  // axios.get('/SAPMAP.json').then((response) => {
  //   controller.SAPMAP = response.data
  //   controller.setState({SAPMAPLoaded: true})
  //   console.log("SAPMAP Loaded")
  // }).catch((error) => {
  //   console.log("loadSAPMAP error")
  // })
}
