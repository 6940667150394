import { WasmHandler } from 'react-lib/frameworks/WasmController';
import { tableFromJSON } from "apache-arrow";
import { query } from "ismor-lib/utils/DuckLib";
import app from 'firebase/compat/app';
import { DownloadDataAsJsonFile } from 'ismor-lib/utils/File';

export type State = {
  dpDataName?: string | null,
  dpDataUrl?: string | null,
  dpData?: any
  ihMaster?: any,
  ihSchema?: any,
  webviewData?: any,
}

export const StateInitial = {
  dpDataName: null,
  dpDataUrl: null,
  dpData: null,
  ihMaster: null,
  ihSchema: null,
  webviewData: null,
}

export type Event =
  { message: "DPGetData", params: {url: string} }
  | { message: "MasterCreate", params: { xdId: string } }
  | { message: "MasterGetData", params: {} }
  | { message: "MasterAddTable", params: {masterId: string, table: string} }
  | { message: "MasterUpdateTable", params: {masterId: string, table: string, data: any} }
  | { message: "MasterSchemaUpdate", params: {key: string, value: any} }
  | { message: "MasterMoveTableInTree", 
      params: {toMoveTable: string, toMoveLoc: {grouIndex: number, itemIndex: number}} }
  | { message: "MasterTreeGroup", 
      params: {action: string, groupIndex: number, name?: string} }

export type Handler = WasmHandler<State, Event>

export type Data = {
  duckCon?: any,
  unsubscribeIHMaster?: any,
  unsubscribeIHSchema?: any
}

export const DataInitial: Data = {  
  unsubscribeIHMaster: () => {},
  unsubscribeIHSchema: () => {}
}

export const DPGetData: Handler = async (controller, params) => {
  let { name, url, format } = params;
  let { duckCon } = controller.data;
  if (!url) return;

  let data = null;
  if (controller.getState().dpDataUrl !== url) {
    controller.setState({dpData: null})
    let downloadUrl = await controller.storage.refFromURL(`gs://ismor-dp/${url}`)
    .getDownloadURL();
    if (format === "json") {
      data = await (await fetch(downloadUrl)).json();
    }
  } else {
    data = controller.getState().dpData;
  }

  // Save to duck ------------------------------------------------
  if (["fk.json", "fk_ih.json"].includes(url) && data) {
    let data1 = data.map((item: any) => 
      ({...item, from: item.from.join(","), to: item.to.join(",")}));
    let arrowTable = tableFromJSON(data1);

    try {
      await duckCon.query(`drop table if exists arrowTable;`)
    } catch {}
    try {
      await duckCon.insertArrowTable(arrowTable, 
        {name: "arrowTable", schema: "main", create: true})
    } catch(e: any) { console.log(e); }; 
    try {
      await query(`
      create or replace table fk_relation as
      from arrowTable
      select
        name,
        list_filter(string_split("from", ','), x -> x != '') "from",
        list_filter(string_split("to", ','), x -> x != '') "to",
        rank
      ;`, duckCon);
    } catch (e: any) { console.log(e); }
  }
  
  // Set state -----------------------------------------------------
  if (params?.loading?.current)
    params.loading.current = false;
  controller.setState({dpDataName: name, dpDataUrl: url, dpData: data});
}

export const MasterCreate: Handler = async (controller, {xdId, setMasterExist}) => {
  if (!xdId) return console.log("No xdId passed.");
  let res = await controller.db.collection("IHMaster").doc(xdId).get();
  if (res.exists) return console.log(`Master ${xdId} already exists`);
  await controller.db.collection("IHMaster").doc(xdId).set({xdId: xdId, data: {}});
  await MasterGetData(controller, {xdId, setMasterExist});
}

export const MasterGetData: Handler = async (controller, {xdId, setMasterExist}) => {
  let res = await controller.db.collection("IHMaster").doc(xdId).get();
  if (!res.exists) return setMasterExist(false);
  let ihMaster = res.data();
  setMasterExist(true);
  controller.setState({ihMaster: ihMaster});
  if (!controller.getState().ihSchema) MasterSchemaGet(controller, {});

  controller.data?.unsubscribeIHMaster?.();
  controller.data.unsubscribeIHMaster = controller.db.collection("IHMaster")
    .where("__name__", "==", xdId)
    .onSnapshot({
      next: (querySnapshot: app.firestore.QuerySnapshot) => {
        let len = querySnapshot.docChanges().length;
        controller.setState({ihMaster: querySnapshot.docChanges()[len-1].doc.data()});
      },
      error: () => {}
    });
}

export const MasterSchemaGet: Handler = async (controller, {}) => {
  let res = await controller.db.collection("masterdata").doc("IHSchema").get();
  // DownloadDataAsJsonFile(res.data(), "IHSchema");
  controller.setState({ihSchema: res.data() || {}});

  controller.data?.unsubscribeIHSchema?.();
  controller.data.unsubscribeIHSchema = controller.db.collection("masterdata")
    .doc("IHSchema")
    .onSnapshot({
      next: (documentSnapshot: app.firestore.DocumentSnapshot) => {
        controller.setState({ihSchema: documentSnapshot.data()});
      },
      error: () => {}
    });
}

export const MasterSchemaUpdate: Handler = async (controller, {key, value}) => {
  if (!key || !value) return console.log("Insufficient params.");
  controller.db.collection("masterdata").doc("IHSchema").update({[key]: value});
}

export const MasterAddTable: Handler = async (controller, {masterId, table}) => {
  if (!masterId || !table) return console.log("Insufficient params");
  
  let { ihMaster } = controller.getState();
  if (!ihMaster) return console.log(`ihMaster not loaded`);

  if (!ihMaster?.data?.[table]) {
    controller.db.collection("IHMaster").doc(masterId).update({[`data.${table}`]: []});
  }
}

export const MasterUpdateTable: Handler = async (controller, {masterId, table, data}) => {
  if (!masterId || !table) return console.log("Insufficient params");
  
  let { ihMaster } = controller.getState();
  if (!ihMaster) return console.log(`ihMaster not loaded`);

  if (ihMaster?.data?.[table]) {
    controller.db.collection("IHMaster").doc(masterId).update({[`data.${table}`]: data});
  } else {
    console.log("No such table");
  }
}

export const MasterMoveTableInTree: Handler = async (controller, {toMoveTable, toLoc, fromLoc}) => {
  if (!toMoveTable || !toLoc || !Number.isInteger(toLoc.groupIndex) 
      || !Number.isInteger(toLoc.itemIndex)
  ) return console.log(`Insuffient params. toMoveTable: ${toMoveTable} toMoveLoc: ${toLoc}`);
  let { ihSchema } = controller.getState();
  if (!ihSchema) return console.log("No ihSchema");
  if (!ihSchema?.treeDisplay?.length) 
    return console.log("ihSchema not an array.");
  if (toLoc.groupIndex >= ihSchema.treeDisplay.length) 
    return console.log("groupIndex out of bound");
  if (!Number.isInteger(ihSchema.treeDisplay[toLoc.groupIndex]?.items?.length))
    return console.log(`items not valid for treeDisplay[${toLoc.groupIndex}]`);
  if (toLoc.itemIndex > ihSchema.treeDisplay[toLoc.groupIndex].items.length)
    return console.log(`itemIndex out of bound`);
  if (!fromLoc) {
    // Move from ignoreList
    ihSchema.treeDisplay[toLoc.groupIndex].items.splice(toLoc.itemIndex, 0, toMoveTable);
  } else if (fromLoc.groupIndex === toLoc.groupIndex) {
    // Move within the same group
    let newItems: string[] = [];
    for (var i = 0; i < ihSchema.treeDisplay[toLoc.groupIndex].items.length; i++) {
      if (i === fromLoc.itemIndex) {
        continue
      } else if (i === toLoc.itemIndex) {
        newItems.push(ihSchema.treeDisplay[toLoc.groupIndex].items[fromLoc.itemIndex]);
        newItems.push(ihSchema.treeDisplay[toLoc.groupIndex].items[i]);
      } else {
        newItems.push(ihSchema.treeDisplay[toLoc.groupIndex].items[i]);
      }
    }
    if (toLoc.itemIndex === ihSchema.treeDisplay[toLoc.groupIndex].items.length) {
      newItems.push(ihSchema.treeDisplay[toLoc.groupIndex].items[fromLoc.itemIndex]);
    }
    ihSchema.treeDisplay[toLoc.groupIndex].items = newItems;
  } else if (fromLoc.groupIndex !== toLoc.groupIndex) {
    // Move from two different groups
    ihSchema.treeDisplay[fromLoc.groupIndex].items = 
      ihSchema.treeDisplay[fromLoc.groupIndex].items.filter((item: string) => item !== toMoveTable);
    ihSchema.treeDisplay[toLoc.groupIndex].items.splice(toLoc.itemIndex, 0, toMoveTable);
  }
  controller.db.collection("masterdata").doc("IHSchema").update(ihSchema);
}

export const MasterTreeGroup: Handler = async (controller, {action, groupIndex, name}) => {
  if (!Number.isInteger(groupIndex)) return console.log("No groupIndex");
  if (!(["add", "delete", "swapup", "swapdown"].includes(action)) 
      && !(["rename"].includes(action) && name)
  ) return console.log("Invalid params");
  let { ihSchema } = controller.getState();
  if (!ihSchema) return console.log("No ihSchema");
  if (!ihSchema?.treeDisplay?.length) 
    return console.log("ihSchema not an array.");
  if (action === "add" && groupIndex < ihSchema.treeDisplay.length) {
    ihSchema.treeDisplay.splice(groupIndex + 1, 0, {name: "New Group", items: []});
  } else if (action === "delete" && groupIndex < ihSchema.treeDisplay.length) {
    if (ihSchema.treeDisplay[groupIndex]?.items?.length === 0) {
      ihSchema.treeDisplay.splice(groupIndex, 1);
    }
  } else if (action === "rename") {
    ihSchema.treeDisplay[groupIndex].name = name
  } else if (action === "swapup") {
    if (groupIndex - 1 >= 0) {
      let newTreeDisplay: any[] = [];
      for (var i = 0; i < ihSchema.treeDisplay.length; i++) {
        if (i === groupIndex - 1) {
          newTreeDisplay.push(ihSchema.treeDisplay[groupIndex]);
          newTreeDisplay.push(ihSchema.treeDisplay[groupIndex - 1]);
        } else if (i === groupIndex) {
          continue
        } else {
          newTreeDisplay.push(ihSchema.treeDisplay[i]);
        }
      }
      ihSchema.treeDisplay = newTreeDisplay;
    }
  } else if (action === "swapdown") {
    if (groupIndex + 1 < ihSchema.treeDisplay.length) {
      let newTreeDisplay: any[] = [];
      for (var i = 0; i < ihSchema.treeDisplay.length; i++) {
        if (i === groupIndex) {
          newTreeDisplay.push(ihSchema.treeDisplay[groupIndex + 1]);
          newTreeDisplay.push(ihSchema.treeDisplay[groupIndex]);
        } else if (i === groupIndex + 1) {
          continue
        } else {
          newTreeDisplay.push(ihSchema.treeDisplay[i]);
        }
      }
      ihSchema.treeDisplay = newTreeDisplay;
    }
  }
  controller.db.collection("masterdata").doc("IHSchema").update({treeDisplay: ihSchema.treeDisplay}); 
}