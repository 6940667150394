import { Canvas } from "fabric";
import { Components, Element } from "./AnimeType";
import * as Sys from "./AnimeSystem/";

const FPS = 20;

export default class Anime {
  canvas: Canvas;
  record: boolean;
  mediaRecorder: MediaRecorder | null = null;
  stop: number;
  startTime: number;
  curDuration: number;
  elements: any[];
  entities: number[];
  components: Components;
  systems: any[];

  constructor(
    canvas: HTMLCanvasElement, record: boolean = false, duration: number, 
    elements: any[], 
    // systems: any[]
  ) {
    this.canvas = new Canvas(canvas);
    this.record = record;
    this.stop = duration * 1000;
    this.startTime = -1;
    this.curDuration = -1;
    this.elements = elements;
    this.entities = elements
      .map((element: any, index: number) => index)
    this.components = JSON.parse(JSON.stringify(elements))
      .flatMap((element: Element, index: number) => (
        Object.entries(element)
          .map((pair: any) => [index, ...pair])
      ))
      .reduce((acc: Components, cur: any[]) => {
        if (!(Object.keys(acc).includes(cur[1]))) {
          (acc as any)[cur[1] as string] = new Map();
        }
        (acc as any)[cur[1]].set(cur[0], cur[2]);
        return acc;
      }, {elementType: new Map()} as Components);
    // this.systems = systems;
    this.systems = [Sys.imageLoadSystem, Sys.positionSystem, Sys.renderingSystem, Sys.textSystem]
    this.runLoop = this.runLoop.bind(this);
  }

  start() {
    requestAnimationFrame(this.runLoop);
  }

  getRecorder(): MediaRecorder {
    let mediaRecorder: MediaRecorder | null = null;
    mediaRecorder = new MediaRecorder(
      this.canvas.lowerCanvasEl.captureStream(FPS), 
      { mimeType: 'video/webm; codes=vp9'});
    let recordedChunks: any[] = [];
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
          recordedChunks.push(event.data);
      }
    };
    mediaRecorder.onstop = () => {
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'animation.webm';
        a.click();
        recordedChunks = [];
    };
    return mediaRecorder
  }

  async runLoop () {
    if (this.curDuration > this.stop) {
      this.startTime = -1;
      this.curDuration = -1;
      this.mediaRecorder?.stop();
      this.canvas.dispose();
      return
    } 
    if (this.startTime === -1) {
      this.startTime = performance.now();
      if (this.record) {
        this.mediaRecorder = this.getRecorder();
        this.mediaRecorder?.start();
      }
    }
    for (const system of this.systems) {
      this.curDuration = performance.now() - this.startTime;
      await system?.(this, this.curDuration / 1000);
    }
    requestAnimationFrame(this.runLoop);
  }
}
