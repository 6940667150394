import { FabricImage } from "fabric";
import Anime from "../Anime"

export async function imageLoadSystem(anime: Anime, dt: number) {
  let comp = anime.components;
  for (const entityId of anime.entities) {
    if (comp.elementType.get(entityId) !== "image") continue;
    let image = comp?.image?.get(entityId);
    if (image && image?.url) {
      let loadedImage = await FabricImage.fromURL(image.url)
      image = {
        ...image,
        image: loadedImage
      }
      comp.image?.set(entityId, image);
    }
  }
}