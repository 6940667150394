import { useEffect, useRef, useState } from "react";
import { Icon, Input, Button } from "semantic-ui-react";
import { RedmineStatus } from "ismor-lib/utils/Constant";
import BokehChart from "./BokehChart";
import MatplotlibChart from "./MatplotlibChart";
import PriorityStat from "./PriorityStat";
import moment from "moment";

const initialNewPriority = {name: "", issues: [], releases: []};

const PriorityName = ["None", "Low", "Normal", "High", "Urgent", "Immediate"]

export default function PriorityList(props: any) {
  const [menu, setMenu] = useState("Active");
  const [newPriority, setNewPriority] = useState(Object.assign({}, initialNewPriority));
  const [selectedPriorityId, setSelectedPriorityId] = useState<any>(null);
  const [issueLatestUpdates, setIssueLatestUpdates] = useState<any>({});
  
  const [monthDisplay, setMonthDisplay] = useState<any[]>([]);
  const [yearFilter, setYearFilter] = useState<string>(moment().format('YYYY'));
  const [monthFilter, setMonthFilter] = useState<string>(moment().format('YYYYMM'));
  const [priorityDisplay, setPriorityDisplay] = useState<any[]>([]);

  const initialLoad = useRef(false);
  
  useEffect(() => {
    if (!initialLoad.current && props.duckLoaded) {
      initialLoad.current = true;
      props.onEvent({message: "GetPerformanceDev", params: {}});
    }
  }, [props.duckLoaded]);

  useEffect(() => {
    if (Object.keys(props.issueLatestUpdates).length > 0) {
      setIssueLatestUpdates(props.issueLatestUpdates);
    }
  }, [props.issueLatestUpdates]);

  useEffect(() => {
    // monthDisplay --------------------------------------------------------------------
    let monthData = Object.entries(props.priorityDict)
      .filter((entry: [string, any]) => entry[1].active)
      .map((entry: [string, any]) => {
        const deal = props.dealOngoing?.[entry[1]?.dealId];
        const deliverable = 
          deal?.deliverableDict?.[entry[1]?.deliverableId];
        return ([
          entry[0],
          {
            ...entry[1],
            ym: (deal && deliverable) 
              ? `${deliverable.deadline_current.substring(0, 6)}`
              : "000000",
          }
        ]) as [string, any];
      })
      .reduce((acc: any, cur: [string, any]) => {
        if (!(cur[1].ym in acc)) {
          acc[cur[1].ym] = {issues: [], releases: []}
        }
        acc[cur[1].ym].issues = acc[cur[1].ym].issues.concat(cur[1]?.issues || []);
        acc[cur[1].ym].releases = acc[cur[1].ym].releases.concat(cur[1]?.releases || []);
        return acc
      }, {});
    let monthDisplay_ = Object.entries(monthData)
      .filter((entry: [string, any]) => entry[0] !== "000000")
      .map((entry: [string, any]) => ({
        label: entry[0],
        issues: Array.from(new Set(entry[1].issues)),
        releases: Array.from(new Set(entry[1].releases)),
      }))
      .sort((a: any, b: any) => a.label < b.label ? -1 : 1);  
    setMonthDisplay(monthDisplay_);

    // priorityDisplay --------------------------------------------------------------------
    const priorityDisplay_ = Object.entries(props.priorityDict)
      .filter((entry: [string, any]) => entry[1].active)
      .sort((a: any, b: any) => a[1].name < b[1].name ? -1: 1)
      .map((entry: [string, any]) => {
        const deal = props.dealOngoing?.[entry[1]?.dealId];
        const deliverable = 
          deal?.deliverableDict?.[entry[1]?.deliverableId];
        return ([
          entry[0],
          {
            ...entry[1],
            deliverableDisplay: (deal && deliverable) 
              ? `${deal.clientName} ${deal.dealName} ${deliverable.name}`
              : "Not selected",
            deadlineDisplay: (deal && deliverable) 
              ? `${deliverable.deadline_current}`
              : "No deadline",
          }
        ]) as [string, any];
      })
      .sort((a: [string, any], b: [string, any]) => (
        a[1].deadlineDisplay < b[1].deadlineDisplay ? -1: 1
      ))
      .reduce((acc: [string, any][], cur: [string, any]) => {
        if (acc.length > 0 && 
            cur[1].deadlineDisplay.substring(0, 6) 
              !== acc[acc.length - 1][1].deadlineDisplay.substring(0, 6)
        ) {
          acc[acc.length - 1][1].lastInMonth = true;
        }
        acc.push(cur);
        return acc;
      }, []);
    setPriorityDisplay(priorityDisplay_);
  }, [props.priorityDict, props.dealOngoing]);

  return(
    <div style={{padding: "5px"}}>
      <div style={{display: "flex", borderBottom: "solid #dddddd 1px", alignItems: "center"}}>
        <MenuItem name="Active" menu={menu} setMenu={setMenu} />
        <MenuItem name="Month" menu={menu} setMenu={setMenu} />
        <MenuItem name="Stat" menu={menu} setMenu={setMenu} />

        {/* python -> bokeh chart */}
        {/* <MenuItem name="Inactive" menu={menu} setMenu={setMenu} /> */}
        {/* {props.pythonLoaded && props.bokehLoaded &&
        <MenuItem name="Chart" menu={menu} setMenu={setMenu} />} */}
        {/* <MenuItem name="Chart1" menu={menu} setMenu={setMenu} /> */}
        {/* <MenuItem name="Chart2" menu={menu} setMenu={setMenu} /> */}

        <div style={{flex: 1}}></div>
        <div><Icon name="add" onClick={(e: any) => setMenu("Add")} /></div>
      </div>
      <div style={{height: "90vh", overflow: "auto"}}>
        {menu === "Active" ? //--------------------------------------------------------
        <div>
          <div style={{display: "flex", borderBottom: "solid grey 1px"}}>
            <div style={{display: "flex"}}>
              {(monthDisplay || []).reduce((acc: string[], cur: any) => {
                let y = cur?.label?.substring(0, 4);
                if (!acc.includes(y)) {
                  acc.push(y)
                }
                return acc
              }, [])
              .concat(["Unassigned"])
              .map((y: string, yindex: number) => (
                <div 
                  key={yindex}
                  style={{
                    cursor: "pointer",
                    padding: "0 5px 0 5px",
                    backgroundColor: y === yearFilter ? "lightblue" : "white"
                  }} 
                  onClick={(e: any) => {
                    setYearFilter(y);
                    if (moment().format('YYYY') === y) {
                      setMonthFilter(moment().format('YYYYMM'))
                    } else {
                      setMonthFilter("All");
                    }
                  }}>
                  {y}
                </div>
              ))}
            </div>
            <select
              style={{width: "150px"}}
              value={monthFilter}
              onChange={(e: any) => setMonthFilter(e.target.value)}>
              {[{"label": "All"}].concat(
                (monthDisplay || [])
                .filter((monthItem: any) => monthItem?.label?.substring(0, 4) === yearFilter)
              )
              .map((monthItem: any, monthIndex: number) => (
                <option key={monthIndex} value={monthItem.label}>
                  {monthItem.label}
                </option>
              ))}
            </select>
          </div>
          {priorityDisplay
            .filter((entry: [string, any]) =>
              yearFilter !== 'Unassigned' 
              ? entry[1]?.deadlineDisplay?.substring(0, 4) === yearFilter 
              : !entry[1]?.dealId)
            .filter((entry: [string, any]) => 
              monthFilter === "All" ? true 
              : entry[1]?.deadlineDisplay?.substring(0, 6) === monthFilter)
            .map((entry: [string, any], entryIndex: number) => (
            <PriorityItem 
              key={entryIndex}
              onEvent={props.onEvent}
              issueLatestUpdates={issueLatestUpdates}
              priorityId={entry[0]}
              priorityItem={entry[1]}
              selectedPriorityId={selectedPriorityId}
              setSelectedPriorityId={setSelectedPriorityId}
              dealOngoing={props.dealOngoing}
            />
          ))}
        </div>
        
        : menu === "Month" ?
        <div>
          {(monthDisplay || []).map((monthItem: any, monthIndex: number) => {
            const releaseIssueCount = Object.values(issueLatestUpdates)
            .filter((issue: any) => 
              (monthItem?.releases || []).includes(issue.release_id?.toString())
            ).length;
            return(
            <div key={monthIndex} style={{display: "grid", gridTemplateColumns: "250px 1fr", borderBottom: "solid #cccccc 1px"}}>
              <div style={{display: "flex", alignItems: "center"}}>
                <div style={{width: "75px"}}>{monthItem.label}</div>
                <div>
                  <div>
                    {monthItem.releases?.length ?
                      (`${monthItem.releases?.length} ` + 
                      `release${monthItem.releases?.length > 1 ? "s" : ""} = ` + 
                      `${releaseIssueCount}` + 
                      ` issue${releaseIssueCount > 1 ? "s" : ""}`)
                      : "No release"}
                  </div>
                  <div>
                    {monthItem.issues?.length ?
                      (`${monthItem.issues?.length} ` + 
                      `issue${monthItem.issues?.length > 1 ? "s" : ""}`)
                      : "No issue"}
                  </div>
                </div>
              </div>
              <PriorityCompletionBar 
                priorityItem={monthItem}
                issueLatestUpdates={issueLatestUpdates}
              />
            </div>)
          })}
        </div>

        : menu === "Inactive" ? //-------------------------------------------------------
        <div>Inactive</div>

        : menu === "Stat" ? //-----------------------------------------------------------
        <PriorityStat 
          onEvent={props.onEvent}
          priorityDict={props.priorityDict}
          issueLatestUpdates={issueLatestUpdates}
          dealOngoing={props.dealOngoing}
          duck={props.duck}
          duckCon={props.duckCon}
          duckLoaded={props.duckLoaded}
          bokehLoaded={props.bokehLoaded}
          performanceDevRaw={props.performanceDevRaw}
          devList={props.devList}
        />

        : menu === "Chart" ? //----------------------------------------------------------
        <BokehChart  
          pythonLoaded={props.pythonLoaded} 
          bokehLoaded={props.bokehLoaded}
          pythonError={props.pythonError}
          python={props.python}
          rawData={props.priorityDict}
          menu={menu}
          script={`
          df = pd.DataFrame(rawData.values())
          df["issue_count"] = df["issues"].apply(
              lambda d: 0 if d.__class__.__name__ == "float" else len(d))
          df = df.sort_values("issue_count", ascending=True).set_index("name")
          plot = df[["issue_count"]].hvplot.barh(x="name", stacked=True, height=850, width=1200)
          p = hv.render(plot, backend='bokeh')
          `}
        />
        : menu === "Chart1" ?
        <BokehChart  
          pythonLoaded={props.pythonLoaded} 
          bokehLoaded={props.bokehLoaded}
          pythonError={props.pythonError}
          python={props.python}
          rawData={props.priorityDict}
          menu={menu}
          script={`
          df = pd.DataFrame(rawData.values())
          df["issue_count"] = df["issues"].apply(
            lambda d: 0 if d.__class__.__name__ == "float" else len(d))
          df = df.sort_values("issue_count", ascending=False)
          names = df["name"].to_list()
          issue_counts = df["issue_count"].to_list()
          p = figure(x_range=names, height=750, width=800, title="Issue Count", toolbar_location=None, tools="")
          p.vbar(x=names, top=issue_counts, width=0.9)
          p.xgrid.grid_line_color=None
          p.xaxis.major_label_orientation = "vertical"
          p.y_range.start = 0
          `}
        />
        : menu === "Chart2" ?
        <MatplotlibChart
        />
        : menu === "Add" ? //------------------------------------------------------------
        <div style={{padding: "5px"}}>
          <div>Add new priority</div>
          <div>
            <Input 
              sie="mini" fluid placeholder="ใส่ชื่อหัวข้อ Priority"
              value={newPriority.name} 
              onChange={(e: any) => {
                setNewPriority({...newPriority, name: e.target.value})
              }}
            />
          </div>
          <Button size="mini" color="green"
            onClick={(e: any) => {
              props.onEvent({message: "AddNewPriority", params: {newPriority}});
              setNewPriority(Object.assign({}, initialNewPriority));
              setMenu("Active");
              setYearFilter("Unassigned");
              setMonthFilter("All");
            }}>
            Add
          </Button>
          <Button size="mini" color="red"
            onClick={(e: any) => {
              setNewPriority(Object.assign({}, initialNewPriority));
              setMenu("Active");
            }}>
            Cancel
          </Button>
        </div>
        
        :
        <></>}
      </div>
    </div>
  )
}

function MenuItem(props: any) {
  return(
    <div 
      style={{
        padding: "5px", cursor: "pointer",
        backgroundColor: props.name === props.menu ? "#dddddd" : "white"
      }}
      onClick={(e: any) => props.setMenu(props.name)}>
      {props.name}
    </div>
  )
}

function PriorityItem(props: any) {
  const [showNote, setShowNote]= useState(false);
  const [newName, setNewName] = useState<string|null>(null);
  const [displayItems, setDisplayItems] = useState<any[]>([]);
  const [editMode, setEditMode] = useState<string>("Issues")
  const [itemMode, setItemMode] = useState("View");
  const [addingType, setAddingType] = useState<string|null>(null);
  const [addedItem, setAddedItem] = useState<string|null>(null);
  const [deletingType, setDeletingType] = useState<string|null>(null);
  const [deletedItem, setDeletedItem] = useState<string|null>(null);
  const [newNote, setNewNote] = useState<string|null>(null);
  const [noteEdited, setNoteEdited] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string>("0");
  const [subjectFilter, setSubjectFilter] = useState("");

  useEffect(() => {
    if (props.selectedPriorityId === props.priorityId) {
      setEditMode("Issues");
      // console.log("match", props.priorityItem);
      let displayItems_ = Object.values(props.issueLatestUpdates)
        .filter((row: any) => 
          (props.priorityItem.issues || []).includes(row.id?.toString()) || 
          (props.priorityItem.releases || []).includes(row.release_id?.toString())
        )
        .map((row: any) => ({
          issue_id: row.id, 
          release_id: row.release_id, 
          status_id: row.status_id,
          subject: row.subject,
        }))
      setDisplayItems(displayItems_);
    } else {
      setItemMode("View");
    }
  }, [props.selectedPriorityId, props.issueLatestUpdates,  props.priorityItem]);

  const releaseIssueCount = Object.values(props.issueLatestUpdates)
    .filter((issue: any) => 
      (props.priorityItem?.releases || []).includes(issue.release_id?.toString())
    ).length;

  let clientDict = Object.values(props.dealOngoing || {})
    .reduce((acc: any, cur: any) => {
      if (cur?.status === 'ONGOING' && cur?.clientId && !(cur?.clientId in acc)) {
        acc[cur.clientId] = cur.clientName;
      }
      return acc;
    }, {});

  return(
    <div 
      style={{
        cursor: "pointer", width: "100%", 
        borderBottom: props.priorityItem?.lastInMonth ? "solid grey 2px": "solid #dddddd 1px",
        paddingBottom: props.priorityItem?.lastInMonth ? "15px": "none",
      }}>
      {props.priorityId !== props.selectedPriorityId ? 
      // Priority Summary ----------------------------------------------------------------
      <div 
        style={{display: "grid", gridTemplateColumns: "700px 1fr"}}
        onClick={(e: any) => props.setSelectedPriorityId(props.priorityId)}>
        <div>
          <div style={{fontWeight: "bold", display: "flex"}}>
              <div style={{paddingRight: "5px"}}>{`[${props.priorityItem?.clientName}] `}</div>
              <div>{`${props.priorityItem.name}`}</div>
              <div
                onMouseEnter={(e: any) => setShowNote(true)}
                onMouseLeave={(e: any) => setShowNote(false)}
                style={{
                  marginLeft: "5px", 
                  color: props.priorityItem?.note?.length > 0 ? "orange" : "grey", 
                  position: "relative"
                }}>
                <Icon name="sticky note" />
                {showNote && props.priorityItem?.note?.length > 0 &&
                <div 
                  onClick={(e: any) => e.stopPropagation()}
                  style={{
                    position: "absolute", top: 5, left: 0, zIndex: 1000
                  }}>
                  <textarea 
                    onChange={(e: any) => {}}
                    style={{width: "500px", height: "200px", border: "solid grey 1px"}}
                    value={props.priorityItem?.note || ""} 
                  />
                </div>}
              </div>
          </div>
          <div 
            style={{display: "grid", width: "100%", gridTemplateColumns: "330px 100px 200px 1fr"}} 
            >
            <div style={{marginLeft: "40px"}}>
              {`${props.priorityItem.deliverableDisplay}`}
            </div>
            <div>
              {`${props.priorityItem.deadlineDisplay}`}
            </div>
            <div>
              {props.priorityItem.releases?.length ?
                (`${props.priorityItem.releases?.length} ` + 
                 `release${props.priorityItem.releases?.length > 1 ? "s" : ""} = ` + 
                 `${releaseIssueCount}` + 
                 ` issue${releaseIssueCount > 1 ? "s" : ""}`)
                : "No release"}
            </div>
            <div>
              {props.priorityItem.issues?.length ?
                (`${props.priorityItem.issues?.length} ` + 
                 `issue${props.priorityItem.issues?.length > 1 ? "s" : ""}`)
                : "No issue"}
            </div>
          </div>
        </div>
        <PriorityCompletionBar 
          priorityItem={props.priorityItem}
          issueLatestUpdates={props.issueLatestUpdates}
        />
      </div>
      :
      // Priority detail / edit form --------------------------------------------------------------
      <div 
        style={{backgroundColor: itemMode === "Delete" ? "pink" : "#eeeeee"}}
        onClick={(e: any) => {
          props.setSelectedPriorityId(null)
        }}>
        <div style={{display: "grid", gridTemplateColumns: "700px 1fr"}}>
          <div style={{display: "flex", alignItems: "flex-start", padding: "5px"}}>
            {newName === null ?
            <div style={{display: "flex"}}>
              <div style={{marginRight: "20px", fontWeight: "bold"}}>
                {`${props.priorityItem.name}`}
              </div>
              <Icon 
                name="edit" 
                onClick={(e: any) => {
                  e.stopPropagation();
                  setNewName(props.priorityItem.name)
                }}
              />
              <Icon 
                name="refresh" 
                onClick={(e: any) => {
                  e.stopPropagation();
                  props.onEvent({message: "AddPriorityItem", 
                    params: {priorityId: props.priorityId, type: "refresh"}});
                }}
              />
            </div>
            :
            <div onClick={(e: any) => e.stopPropagation()}>
              <input 
                value={newName}
                onChange={(e: any) => setNewName(e.target.value)}
              />
              <Button size="mini" color="green"
                onClick={(e: any) => {
                  props.onEvent({message: "UpdatePriority", params: 
                    {priorityId: props.priorityId, data: {name: newName}}});
                  setNewName(null);
                }}>
                Done
              </Button>
              <Button size="mini" color="red"
                onClick={(e: any) => {
                  setNewName(null);
                }}>
                Cancel
              </Button>
            </div>}
            {/* Deal selector ----------------------------------------------------------------- */}
            <div style={{flex: 1}}></div>
            <select
              value={props.priorityItem?.clientId || "[NOT SELECTED]"}
              onClick={(e: any) => e.stopPropagation()}
              onChange={(e: any) => {
                let selectedClientId: string = e.target.value;
                if (selectedClientId !== null && selectedClientId !== "[NOT SELECTED]") {
                  props.onEvent({message: "UpdatePriority", params: {
                    priorityId: props.priorityId, 
                    data: {
                      clientId: selectedClientId,
                      clientName: (clientDict as any)?.[selectedClientId]
                    }
                  }});
                }
              }}>
              {([["[NOT SELECTED]", "Not selected"]].concat(
                Object.entries(clientDict || {}).sort((a: any, b: any) => a[1] < b[1] ? -1 : 1)
              ))
              .map((entry: any, entryIndex: number) => { 
                return(
                  <option 
                    key={entryIndex}
                    value={entry[0]}>
                    {entry[1]}
                  </option>
                )})}
            </select>
            <select
              style={{width: "300px"}}
              onClick={(e: any) => e.stopPropagation()}
              onChange={(e: any) => {
                if (e.target.value !== null) {
                  const params = e.target.value.split(":");              
                  props.onEvent({message: "UpdatePriority", params: {
                    priorityId: props.priorityId, 
                    data: {
                      dealId: 
                        params.length === 2 && params[0] !== "null" 
                        ? params[0] : null,
                      deliverableId: 
                        params.length === 2 && params[1] !== "null" 
                        ? params[1] : null,
                    }
                  }});
                }
              }}
              value={`${props.priorityItem?.dealId}:${props.priorityItem?.deliverableId}`}>
              {[
                {
                  clientId: null,
                  clientName: "Not selected", 
                  dealId: null, 
                  dealName: "",
                  deliverableId: null, 
                  deliverableName: "", 
                  deliverableStatus: "",
                  deadline_current: "",
                } as any]
              .concat(
                Object.values(props.dealOngoing || {})
                  .filter((deal: any) => (
                    deal?.clientId === props.priorityItem?.clientId
                  ))
                  .flatMap((deal: any) => 
                    (Object.entries(deal.deliverableDict || {}))
                    .map((entry: [string, any]) => (
                      {
                        clientId: deal.clientId,
                        clientName: deal.clientName,
                        dealId: deal.dealId,
                        dealName: deal.dealName,
                        deliverableId: entry[0],
                        deliverableName: entry[1].name,
                        deliverableStatus: entry[1].status,
                        deadline_current: entry[1].deadline_current
                      }
                    )))
                  .sort((a: any, b: any) => 
                    `${a.clientName} ${a.dealName} ${a.deliverableName}` 
                    < `${b.clientName} ${b.dealName} ${b.deliverableName}` 
                    ? -1 : 1))
              .sort((a: any, b: any) => a?.deadline_current < b?.deadline_current ? -1: 1)
              .map((deliverable: any, deliverableIndex: number) => (
                <option 
                  key={deliverableIndex}
                  value={`${deliverable.dealId}:${deliverable.deliverableId}`}>
                  {`${deliverable.deadline_current || "No deadline"} [${deliverable.clientName}] ${deliverable.deliverableName} ${deliverable.dealName} `}
                </option>
              ))}
            </select>
          </div>
          <div style={{width: "100%"}}>
            <PriorityCompletionBar 
              priorityItem={props.priorityItem}
              issueLatestUpdates={props.issueLatestUpdates}
            />
          </div>
          
        </div>

        <div style={{paddingLeft: "5px"}}>{`[${props.priorityId}]`}</div>

        {/* Menu bar for Issues / Note edit */}
        <div style={{display: "flex", borderBottom: "solid #aaaaaa 1px"}}>
          <div
            style={{padding: "5px", backgroundColor: editMode === "Issues" ? "orange" : "#eeeeee"}}
            onClick={(e: any) => {
              e.stopPropagation();
              setEditMode("Issues");
            }}>
            Issues
          </div>
          <div  
            style={{padding: "5px", backgroundColor: editMode === "Note" ? "orange" : "#eeeeee"}}
            onClick={(e: any) => {
              e.stopPropagation();
              setNewNote(props.priorityItem.note)
              setEditMode("Note");
              setNoteEdited(false);
            }}>
            Note
          </div>
        </div>

        {editMode === "Issues" ?
        <>
          {itemMode === "View" ? // Issue top bar ------------------------------------------------------
          <div 
            style={{display: "flex"}}
            onClick={(e: any) => {
              e.stopPropagation();
            }}>
            <div 
              style={{marginRight: "10px"}}
              onClick={(e: any) => {
                setItemMode("Add");
                setAddingType("issue");
              }}>
              <Icon name="add"/>Add Issue
            </div>
            <div 
              style={{marginRight: "10px"}}
              onClick={(e: any) => {
                setItemMode("Add");
                setAddingType("release")
              }}>
              <Icon name="add"/>Add Release
            </div>
            <div 
              onClick={(e: any) => {
                setItemMode("Delete");
              }}>
              <Icon name="delete"/>Delete Mode
            </div>
            <select 
              style={{marginLeft: "10px"}}
              value={statusFilter}
              onChange={(e: any) => {
                setStatusFilter(e.target.value)
              }}>
              {[["0", "No filter", displayItems.length]].concat(
                  Object.entries(RedmineStatus)
                    .map((entry: any) => (
                      [...entry, displayItems.filter((displayItem: any) => displayItem.status_id?.toString() === entry[0]).length]
                    ))
                )
                .map((entry: any, optionIndex: number) => (
                <option
                  key={optionIndex}
                  value={entry[0].toString()}>
                  {`${entry[1]} (${entry[2]})`}
                </option>
              ))}
            </select>
            <input 
              style={{marginLeft: "5px"}}
              placeholder="กรองหัวข้อ"
              value={subjectFilter}
              onChange={(e: any) => setSubjectFilter(e.target.value)}
            />
          </div>
          : itemMode === "Add" ?
          <div
            onClick={(e: any) => {
              e.stopPropagation();
            }}>
            <Input 
              size="mini" value={addedItem || ""} 
              placeholder={addingType === "issue" ? "ใส่เลข Issue" : "ใส่เลข Release"}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  props.onEvent({message: "AddPriorityItem", 
                    params: {priorityId: props.priorityId, type: addingType, item: addedItem}});
                  setAddedItem(null);
                }
              }}
              onChange={(e: any) => {
                if (!isNaN(Number(e.target.value))) {
                  setAddedItem(e.target.value);
                }
              }} 
            />
            <Button size="mini" color="green"
              onClick={(e: any) => {
                props.onEvent({message: "AddPriorityItem", 
                  params: {priorityId: props.priorityId, type: addingType, item: addedItem}});
                setAddedItem(null);
              }}>
              Add
            </Button>
            <Button size="mini" color="red"
              onClick={(e: any) => {
                setAddedItem(null);
                setItemMode("View");
                setAddingType(null);
              }}>
              Cancel/Finish
            </Button>
          </div>
          : itemMode === "Delete" ?
          <div 
            onClick={(e: any) => {
              e.stopPropagation();
              setItemMode("View")
            }}>
            &lt;&lt;Finish Deleting - Back to View Mode
          </div>
          :
          <></>
          } 
          
          {/* Issue list --------------------------------------------------------------------- */}
          <div style={{height: "40vh", overflowY: "auto"}}>
            {(displayItems || [])
              .filter((issue: any) => statusFilter === "0" ? true : (issue.status_id?.toString() === statusFilter.toString()))
              .filter((issue: any) => subjectFilter === "" ? true : issue.subject.toLowerCase().includes(subjectFilter.toLowerCase()))
              // .sort((a: any, b: any) => (
              //   props.issueLatestUpdates?.[a.issue_id]?.case_name < props.issueLatestUpdates?.[b.issue_id]?.case_name ? -1 : 1
              // ))
              .map((issue: any, issueIndex: number) => (
              <div 
                key={issueIndex} 
                style={{
                  display: "grid", 
                  // gridTemplateColumns: "50px 150px 100px 500px 200px 50px 1fr",
                  gridTemplateColumns: "50px 75px 75px 75px 525px 200px 50px 1fr",
                  borderBottom: "solid #dddddd 1px",
                  textDecoration: 
                      (itemMode === "Delete" && deletingType === "release" && deletedItem === issue.release_id
                      && (props.priorityItem?.releases || []).includes(issue.release_id?.toString()))
                      || 
                      (itemMode === "Delete" && deletingType === "issue" && deletedItem === issue.issue_id
                      && (props.priorityItem?.issues || []).includes(issue.issue_id?.toString()))
                      ?  "line-through" : "none"
                }}>
                <span
                  onMouseEnter={(e: any) => {
                    if (itemMode === "Delete") {
                      setDeletingType("issue");
                      setDeletedItem(issue.issue_id);
                    }
                  }}
                  onMouseLeave={(e: any) => {
                    if (itemMode === "Delete") {
                      setDeletingType(null);
                      setDeletedItem(null);
                    }
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (itemMode === "View") {
                      window.open(`https://redmine.thevcgroup.com/issues/${issue.issue_id}`, "_blank");
                    } else if (itemMode === "Delete" && 
                      (props.priorityItem?.issues || []).includes(issue.issue_id?.toString())
                    ) {
                      props.onEvent({message: "DeletePriorityItem", 
                        params: {priorityId: props.priorityId, type: deletingType, item: deletedItem}});
                    }
                  }}>
                  <a>{issue.issue_id}</a>
                </span>
                {/* <span>
                  {`${props.issueLatestUpdates?.[issue.issue_id]?.case_name || ""}`}
                </span> */}
                <span>{` ${RedmineStatus?.[props.issueLatestUpdates?.[issue.issue_id]?.status_id] || ""}`}</span>
                <span>{` ${props.issueLatestUpdates?.[issue.issue_id]?.tracker || ""}`}</span>
                <span>{` ${PriorityName?.[props.issueLatestUpdates?.[issue.issue_id]?.priority_id] }`}</span>
                <span>{` ${props.issueLatestUpdates?.[issue.issue_id]?.subject || ""}`}</span>
                <span>{` ${props.issueLatestUpdates?.[issue.issue_id]?.assign || "Not assigned"}`}</span>
                <span
                  onMouseEnter={(e: any) => {
                    if (itemMode === "Delete" 
                        && (props.priorityItem?.releases || [])
                            .includes(issue.release_id?.toString())
                    ) {
                      setDeletingType("release");
                      setDeletedItem(issue.release_id);
                    }
                  }}
                  onMouseLeave={(e: any) => {
                    if (itemMode === "Delete") {
                      setDeletingType(null);
                      setDeletedItem(null);
                    }
                  }}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    if (itemMode === "View") {
                      window.open(`https://redmine.thevcgroup.com/rb/release/` + 
                        `${props.issueLatestUpdates?.[issue.issue_id]?.release_id}`, "_blank");
                    } else if (itemMode === "Delete" && 
                      (props.priorityItem?.releases || []).includes(issue.release_id?.toString())
                    ) {
                      props.onEvent({message: "DeletePriorityItem", 
                        params: {priorityId: props.priorityId, type: deletingType, item: deletedItem}});
                    }
                  }}>
                  <a>
                    {` ${props.issueLatestUpdates?.[issue.issue_id]?.release_id || "No release"}`}
                  </a>
                </span>
                <span
                  style={{
                    color: (props.priorityItem?.releases || [])
                            .includes(issue.release_id?.toString()) ? "green" :"black"
                  }}>
                  {` ${props.issueLatestUpdates?.[issue.issue_id]?.release_name || ""}`}
                </span>
              </div>
            ))}
          </div>
          
        
        </>
        :
        <div onClick={(e: any) => e.stopPropagation()}>
          <textarea
            value={newNote || ""}
            onChange={(e: any) => {
              setNewNote(e.target.value);
              setNoteEdited(true);
            }}
            style={{height: "40vh", width: "100%"}}
          />
          <div style={{display: "flex"}}>
            <Button size="mini" color="green"
              onClick={(e: any) => {
                props.onEvent({message: "UpdatePriority", params: 
                  {priorityId: props.priorityId, data: {note: newNote}}});
                setNoteEdited(false);
              }}>
              Save
            </Button>
            <Button size="mini" color="red"
              onClick={(e: any) => {
                setNewNote(props.priorityItem.note);
                setNoteEdited(false);
              }}>
              Cancel
            </Button>
            {noteEdited &&
            <div style={{color: "red"}}>You have unsaved changes.</div>}
          </div>
        </div>
        }
        
        {/* -------------------------------------------------------------------------------------- */}
      </div>}
    </div>
  )
}

function PriorityCompletionBar(props: any) {
  let statusCount: {[key: string]: number} = Object.values(props.issueLatestUpdates)
    .filter((row: any) => 
      (props.priorityItem.issues || []).includes(row.id?.toString()) || 
      (props.priorityItem.releases || []).includes(row.release_id?.toString())
    )
    .map((row: any) => ({issue_id: row.id, status_id: row.status_id, assign: row.assign}))
    .reduce((acc: any, cur: any) => {
      if (!cur.assign)
        acc.notassigned += 1;
      else if (cur.status_id === 1)
        acc.new += 1;
      else if (cur.status_id === 2)
        acc.inprogress += 1;
      else if (cur.status_id === 3)
        acc.resolved += 1;
      else if (cur.status_id === 4)
        acc.feedback += 1;
      else if (cur.status_id === 5)
        acc.closed += 1;
      else if (cur.status_id === 9)
        acc.fixed += 1;
      else
        acc.other += 1;
      return acc
    }, {
      closed: 0, 
      fixed: 0, 
      resolved: 0, 
      feedback: 0, 
      other: 0,
      inprogress: 0, 
      new: 0, 
      notassigned: 0, 
    });
  const totalSum = Object.values(statusCount)
    .reduce((acc: number, cur: number) => acc + cur, 0);

  const statusColor = ["green", "magenta", "lightgreen", "cyan", "grey", "yellow", "orange", "red"]
  
  let statusDisplay = Object.entries(statusCount)
    .map((entry: [string, any], entryIndex: number) => (
      {name: entry[0], color: statusColor[entryIndex], width: entry[1] / totalSum * 100.0}
    ));

  // console.log(props.priorityItem.name);
  // console.log(statusCount);
  // console.log(statusDisplay);
  // console.log("Completion Bar render")

  return(
    <div style={{display: "flex", width: "100%", padding: "5px", alignItems: "flex-start"}}>
      {totalSum > 0 ?
      statusDisplay.map((item: any, itemIndex: number) => (
        <div 
          key={itemIndex}
          style={{
            width: `${item.width}%`, 
            backgroundColor: item.color, color: "black", fontSize: "0.7rem",
            overflow: "hidden", display: "flex",
            justifyContent: "center", alignItems: "center"
          }}>
          {item.name}
        </div>
      ))
      :
      <div 
        style={{ 
          width: "100%", display: "flex", fontSize: "0.7rem",
          justifyContent: "center", alignItems: "center"
        }}>
        No issue
      </div>}
    </div>
  )
}
