import { useEffect, useRef, useState } from "react";
import Anime from "./Anime";
import { AnimeSpec, Element } from "./AnimeType";

export default function ScreenAnimation(props: any) {
  let [animation, setAnimation] = useState<AnimeSpec | null>(null);
  let [itemIndex, setItemIndex] = useState<number | null>(null);

  let canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    props.onEvent({
      message: "GetAnimation", params: {id: "cj21ofeshH9HQ9D8CHDT", setAnimation}});
  }, []);

  const startAnimate = async (record: boolean) => {
    if (!canvasRef.current || !(animation?.items?.length) ) return;
    let anime = new Anime(
      canvasRef.current, record, 10, animation.items
    );
    anime.start();
  };

  return(
    <div 
      style={{
        width: "100%", height: "95vh", border: "solid grey 1px",
        display: "flex", flexDirection: "column"
      }}>
      <div 
        style={{
          display: "flex", justifyContent: "space-between", 
          borderBottom: "solid grey 1px", backgroundColor: "lightblue"
        }}>
        <b>{animation?.name}</b>
        <div style={{flex: 1}}></div>
        <button
          disabled={!animation}
          onClick={() => startAnimate(false)}>
          Render
        </button>
        <button
          disabled={!animation}
          onClick={() => startAnimate(true)}>
          Render and Record
        </button>
      </div>
      <div style={{display: "grid", gridTemplateColumns: "300px 1fr", flex: 1}}>
        <div style={{borderRight: "solid grey 1px"}}>
          {(animation?.items || [])
          .map((item: any, index: number) => (
            <div 
              key={index} 
              style={{
                borderBottom: "solid grey 1px",
                backgroundColor: itemIndex === index ? "lightgreen" : "white"
              }}
              onClick={(e: any) => itemIndex === index ? setItemIndex(null) : setItemIndex(index)}>
              <b>{item?.elementType}</b>
              {itemIndex === index &&
              <div style={{marginLeft: "15px"}}>
                {Object.entries(item)
                .filter((entry: any) => entry[0] !== "elementType")
                .sort((a: any, b: any) => a[0] < b[0] ? -1 : 1)
                .flatMap((entry: any, entryIndex: number) => (
                  Object.entries(entry[1])
                  .map((subEntry: any, subEntryIndex: number) => (
                    <div 
                      key={`${entryIndex}.${subEntryIndex}`}
                      style={{display: "grid", gridTemplateColumns: "50% 50%"}}>
                      <div>{`${entry[0]}.${subEntry[0]}`}</div>
                      <div>{subEntry?.[1]}</div>
                    </div>
                  ))
                ))}
              </div>}
            </div>
          ))}
        </div>
        <canvas ref={canvasRef}
          width="1000" height="500"
          style={{ border: "solid red 1px"}} 
        />
      </div>
    </div>
  )
}
