import Anime from "../Anime"

export async function positionSystem(anime: Anime, dt: number) {
  let comp = anime.components;
  for (const entityId of anime.entities) {
    let position = comp?.position?.get(entityId);
    if (!position) continue;

    // Check visibility
    let visible = true;
    if (position?.visiblePeriods) {
      for (const period of position.visiblePeriods || []) {
        if (dt < period[0] || (period?.[1] !== null && dt > period[1]))
          visible = false;
      }
    }
    position.visible = visible;
    
    // Move position
    if (position && position.left && position.vleft) {
      position.left = position.left + position.vleft;      
    }
    
    comp.position?.set(entityId, position);
  }
}
