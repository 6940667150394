import { useEffect, useRef, useState } from "react";
import { Checkbox, Modal } from "semantic-ui-react";

export default function CP(props: {
  onEvent: any,
  debug: boolean,
  clientUserDict: Map<string, any>,
  clientContactDict: Map<string, any>,
  clientDict: any,
  clientDeal: any,
  xdmaster: any,
  ihGameList: any[],
}) {
  const [mode, setMode] = useState("User");
  const [userList, setUserList] = useState<any[]>([]);
  const [clientList, setClientList] = useState<any[]>([]);
  const [xdDict, setXdDict] = useState<any>({});
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedClientField, setSelectedClientField] = useState<string|null>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedUserField, setSelectedUserField] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number|null>(null);

  const initialLoad = useRef(false);

  useEffect(() => {
    if (!initialLoad.current) {
      props.onEvent({message: "GetClientUser", params: {}});
      props.onEvent({message: "GetXDMaster", params: {}});
      props.onEvent({message: "GetIHGameListMaster", params: {}});      
      props.onEvent({message: "GetClientData", params: {}});
      initialLoad.current = true;
    }
  }, []);

  useEffect(() => {
    let combinedList = Array.from(
      new Set(
        Array.from(props.clientUserDict.keys())
        .concat(Array.from(props.clientContactDict.keys())))
    );
    setUserList(combinedList.map((id: string) => (
      {
        id, 
        login: props.clientUserDict.has(id), 
        detail: props.clientUserDict.get(id),
        contact: props.clientContactDict.get(id) || null
      }
    )));
  }, [props.clientUserDict, props.clientContactDict]);

  useEffect(() => {
    let clientList_ = Array.from(
      new Set(Object.values(props.clientDeal)
        .filter((deal: any) => ["ONGOING"].includes(deal.status))
        .map((deal: any) => deal.clientId)
        .filter((deal: any) => deal))
      )
      .map((clientId: string) => props.clientDict?.[clientId])
      .filter((client: any) => client)
    setClientList(clientList_);
  }, [props.clientDeal, props.clientDict]);

  useEffect(() => {
    setXdDict(
      Object.fromEntries(
        (props.xdmaster?.allXd || []).map((item: any) => [item.id, item.name]))
    );
  }, [props.xdmaster]);

  const userTemplateColumns = "repeat(7, 1fr)";
  
  return (
    <div style={{height: "94vh"}}>
      <div style={{display: "flex", borderBottom: "solid grey 1px"}}>
        <Menu name="User" mode={mode} setMode={setMode} />
        
        {props.debug &&
        <Menu name="Client" mode={mode} setMode={setMode} />}
      </div>
      {mode === "User"?
      <div
        style={{height: "92vh", overflowY: "auto", padding: "5px"}}>
        <div 
          style={{
            display: "grid", gridTemplateColumns: userTemplateColumns,
            borderBottom: "solid grey 1px", backgroundColor: "lightblue",
          }}>
          <div>Email</div>
          <div style={{textAlign: "center"}}>Name/Org/Role</div>
          <div style={{textAlign: "center"}}>Login</div>
          <div style={{textAlign: "center"}}>Client</div>
          <div style={{textAlign: "center"}}>Video</div>
          <div style={{textAlign: "center"}}>XD</div>
          <div style={{textAlign: "center"}}>Game Sim</div>
        </div>
        {userList
        .sort((a: any, b: any) => (a?.detail?.lastLogin || "1700") < (b?.detail?.lastLogin || "1700") ? 1 : -1)
        .map((user: any, index: number) => {
          let {name, organization, role} = user?.detail;
          return (
            <div 
              key={index} 
              style={{
                display: "grid", gridTemplateColumns: userTemplateColumns,
                borderBottom: "solid grey 1px"
              }}>
              <div>{user.id}</div>
              <div style={{textAlign: "center"}}>{`${name || "(blank)"} / ${organization || "(blank)"} / ${role || "(blank)"}`}</div>
              <div style={{textAlign: "center"}}>{`${user?.detail?.lastLogin || ""}`}</div>
              <div style={{position: "relative", cursor: "pointer", textAlign: "center"}}>
                {user.id === selectedUser?.id && selectedUserField === "clientList" ?
                <div 
                  style={{
                    textAlign: "left",
                    position: "absolute", top: 0, left: 0, zIndex:1000,
                    border: "solid grey 1px", overflowY: "auto",
                    width: "200px", height: "200px", backgroundColor: "white"
                  }}
                  onClick={() => {
                    setSelectedUser(null);
                    setSelectedUserField(null);
                  }}>
                  <div>&lt;&lt; Back</div>
                  {(clientList || [])
                  .sort((a: any, b: any) => a.name < b.name ? -1 : 1)
                  .map((client: any, clientIndex: number) => {
                    return (
                      <div key={clientIndex} style={{display: "flex", padding: "2px"}}>
                        <Checkbox 
                          checked={(user?.contact?.clientList || []).includes(client.id)}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            props.onEvent({message: "SetClientContact", params: {
                              email: user.id,
                              clientList: (user?.contact?.clientList || []).includes(client.id) ?
                                          (user?.contact?.clientList || []).filter((clientId: any) => clientId !== client.id)
                                          : Array.from(new Set((user?.contact?.clientList || []).concat([client.id])))
                            }});
                            setSelectedUser(null);
                            setSelectedUserField(null);
                          }}
                        />
                        <div style={{marginLeft: "10px"}}>{client.name}</div>
                      </div>
                    )
                  })}
                </div>:
                <div
                  onClick={() => {
                    if (!user.login) return;
                    setSelectedUser(user);
                    setSelectedUserField("clientList");
                  }}>
                  {(user?.contact?.clientList || []).length > 0 
                    ? (user?.contact?.clientList || [])
                      .map((clientId: string) => 
                        clientList?.find((item: any) => item?.id === clientId)?.name)
                      .join(", ")
                    : user?.contact ? "No client" 
                    : "-"
                    }
                </div>
                }
              </div>
              <div style={{textAlign: "center"}}>Video</div>
              <div style={{position: "relative", cursor: "pointer", textAlign: "center"}}>
                <div
                  onClick={() => {
                    if (!user.login) return;
                    setSelectedUser(user);
                    setSelectedUserField("xdList");
                  }}>
                  {(user?.contact?.xdList || []).length > 0 
                    ? (user?.contact?.xdList || [])
                      .sort((a: string, b: string) => xdDict?.[a] < xdDict?.[b] ? -1 : 1)
                      .map((xdId: string) => xdDict?.[xdId]).join(", ")
                    : user?.contact ? "No XD" 
                    : "-"
                    }
                </div>
                {user.id === selectedUser?.id && selectedUserField === "xdList" &&
                <div 
                  style={{
                    textAlign: "left",
                    position: "absolute", top: 20, left: 0, zIndex:1000,
                    border: "solid grey 1px", overflowY: "auto",
                    width: "300px", height: "200px", backgroundColor: "white"
                  }}
                  >
                  <div
                    onClick={() => {
                      setSelectedUser(null);
                      setSelectedUserField(null);
                    }}>
                    &lt;&lt; Back
                  </div>
                  {(user?.contact?.clientList || [])
                  .flatMap((clientId: string) =>
                    clientList.find((client: any) => client.id === clientId)?.xdList || [])
                  .sort((a: string, b: string) => xdDict?.[a] < xdDict?.[b] ? -1 : 1)
                  .map((selectedXdId: any, xdIndex: number) => {
                    return(
                      <>
                        <div key={xdIndex} style={{display: "flex", padding: "2px"}}>
                          <Checkbox 
                            checked={(user?.contact?.xdList || []).includes(selectedXdId)}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (message) return;
                              setSelectedIndex(xdIndex);
                              props.onEvent({message: "SetClientContactPermission", params: {
                                email: user.id,
                                data: {
                                  xdList: (user?.contact?.xdList || []).includes(selectedXdId) ?
                                            (user?.contact?.xdList || []).filter((xdId: any) => xdId !== selectedXdId)
                                            : Array.from(new Set((user?.contact?.xdList || []).concat([selectedXdId])))
                                },
                                setMessage,
                                xdDict,
                              }});
                              // setSelectedUser(null);
                              // setSelectedUserField(null);
                            }}
                          />
                          <div style={{marginLeft: "10px"}}>{xdDict?.[selectedXdId]}</div>
                        </div>
                        {selectedIndex === xdIndex && message && 
                        <div style={{backgroundColor: "lightyellow"}}>
                          {message.map((item: string, messageIndex: number) => 
                          <div key={messageIndex} style={{color: "red"}}>{item}</div>)}
                        </div>}
                      </>
                    )
                  })}
                </div>
                }
              </div>
              <div style={{position: "relative", cursor: "pointer", textAlign: "center"}}>
                <div
                  onClick={() => {
                    if (!user.login) return;
                    setSelectedUser(user);
                    setSelectedUserField("gameList");
                  }}>
                  {(user?.contact?.gameList || []).length > 0 
                    ? (user?.contact?.gameList || [])
                      .sort((a: string, b: string) => 
                        props.ihGameList?.find((item: any) => item.id === a)?.name 
                        < props.ihGameList?.find((item: any) => item.id === b)?.name
                        ? -1 : 1)
                      .map((gameId: string) => 
                        props.ihGameList?.find((item: any) => item.id === gameId)?.name
                      ).join(", ")
                    : user?.contact ? "No Game" 
                    : "-"
                    }
                </div>
                {user.id === selectedUser?.id && selectedUserField === "gameList" &&
                <div 
                  style={{
                    textAlign: "left",
                    position: "absolute", top: 20, left: 0, zIndex:1000,
                    border: "solid grey 1px", overflowY: "auto",
                    width: "300px", height: "200px", backgroundColor: "white"
                  }}
                  >
                  <div
                    onClick={() => {
                      if (message) return;
                      setSelectedUser(null);
                      setSelectedUserField(null);
                    }}>
                    &lt;&lt; Back
                  </div>
                  {(props.ihGameList || [])
                  // .filter((game: any) => (user?.contact?.xdList || []).includes(game.xdId))
                  .filter((game: any) => game?.publishUrl)
                  .sort((a: any, b: any) => {
                    if (`[${xdDict?.[a.xdId]}] ${a.name}` < `[${xdDict?.[b.xdId]}] ${b.name}`) {
                      return -1
                    } else {
                      return 1
                    }
                  })
                  .map((game: any, gameIndex: number) => {
                    // console.log(game, gameIndex);
                    return(
                      <div key={gameIndex}>
                        <div key={gameIndex} style={{display: "flex", padding: "2px"}}>
                          <Checkbox 
                            checked={(user?.contact?.gameList || []).includes(game.id)}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (message) return;
                              setSelectedIndex(gameIndex)
                              props.onEvent({message: "SetClientContactPermission", params: {
                                email: user.id,
                                data: {
                                  gameList: (user?.contact?.gameList || []).includes(game.id) ?
                                            (user?.contact?.gameList || []).filter((gameId: any) => gameId !== game.id)
                                            : Array.from(new Set((user?.contact?.gameList || []).concat([game.id])))
                                },
                                setMessage,
                                ihGameList: props.ihGameList,
                              }});
                              // setSelectedUser(null);
                              // setSelectedUserField(null);
                            }}
                          />
                          <div style={{marginLeft: "10px"}}>
                            {`[${xdDict?.[game.xdId]}] ${game.name}`}
                          </div>
                        </div>
                        {selectedIndex === gameIndex && message && 
                        <div style={{backgroundColor: "lightyellow"}}>
                          {message.map((item: string, messageIndex: number) => 
                          <div key={messageIndex} style={{color: "red"}}>{item}</div>)}
                        </div>}
                      </div>
                    )
                  })}
                </div>}
              </div>
            </div>
          )
        })}
      </div>

      : mode === "Client" && props.debug ?
      <div 
        style={{height: "92vh", overflowY: "auto", padding: "5px"}}>
          <div 
            style={{
              display: "grid", gridTemplateColumns: "1fr 1fr 1fr",
              borderBottom: "solid grey 1px", backgroundColor: "lightblue"
            }}>
            <div>Name</div>
            <div>XD</div>
            <div>ID</div>
          </div>
        {clientList
        .sort((a: any, b: any) => a.name < b.name ? -1 : 1)
        .map((client: any, index: number) => {
          return (
            <div 
              key={index} 
              style={{
                display: "grid", gridTemplateColumns: "1fr 1fr 1fr",
                borderBottom: "solid grey 1px"
              }}>
              <div>{client?.name}</div>
              <div style={{position: "relative", cursor: "pointer"}}>
                {client.id === selectedClient?.id && selectedClientField === "xdList" ?
                <div 
                  style={{
                    position: "absolute", top: 0, left: 0, zIndex:1000,
                    border: "solid grey 1px", overflowY: "auto",
                    width: "200px", height: "200px", backgroundColor: "white"
                  }}
                  onClick={() => {
                    setSelectedClient(null);
                    setSelectedClientField(null);
                  }}>
                  <div>&lt;&lt; Back</div>
                  {(props.xdmaster?.allXd || [])
                  .sort((a: any, b: any) => a.name < b.name ? -1 : 1)
                  .map((xd: any, xdIndex: number) => (
                    <div key={xdIndex} style={{display: "flex", padding: "2px"}}>
                      <Checkbox 
                        checked={(client?.xdList || []).includes(xd.id)}
                        onClick={(e: any) => {
                          e.stopPropagation();
                          props.onEvent({
                            message: "EditExistingClient", 
                            params: { 
                              client: { 
                                id: client.id,
                                xdList: (client?.xdList || []).includes(xd.id) 
                                        ? (client?.xdList || []).filter((xdId: string) => xdId !== xd.id)
                                        : Array.from(new Set((client?.xdList || []).concat([xd.id])))
                              }
                            }
                          });
                          setSelectedClient(null);
                          setSelectedClientField(null);
                        }}
                      />
                      <div style={{marginLeft: "10px"}}>{xd.name}</div>
                    </div>
                  ))}
                </div>:
                <div
                  onClick={() => {
                    setSelectedClient(client);
                    setSelectedClientField("xdList");
                  }}>
                  {`${(client?.xdList || []).length === 0 ? "No xd" : 
                    client.xdList.map((xdId: string) => xdDict?.[xdId]).join(", ")}`}
                </div>
                }
              </div>
              <div>{client?.id}</div>
            </div>)
        })}
      </div>
      
      :
      <></>}
      {/* <Modal
        open={message !== null}
        size="small">
        <div
          style={{
            padding: "10px", display: "flex", flexDirection: "column", 
            justifyContent: "center", alignItems: "center", height: "300px"
          }}>
          {(message || []).map((item: string, index: number) => (
            <div key={index} style={{lineHeight: "1.5rem"}}>{item}</div>
          ))}
        </div>
      </Modal> */}
    </div>
  )
}

function Menu(props: any) {
  return(
    <div
      onClick={() => props.setMode(props.name)}
      style={{
        padding: "0 5px 0 5px", cursor: "pointer",
        backgroundColor: props.name === props.mode ? "lightgreen" : "white"
      }}>
      {props.name}
    </div>
  )
}
