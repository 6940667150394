import { useEffect, useRef, useState } from "react";
import { Checkbox, Modal } from "semantic-ui-react";

export default function VD(props: {
  forClient: boolean,
  vdedit: boolean,
  onEvent: any,
  setProp: any,
  clientUserDict: Map<string, any>,
  clientContactDict: Map<string, any>
}) {
  let [videoList, setVideoList] = useState<any[]>([]);
  let [selectedVideo, setSelectedVideo] = useState<any>(null);
  let [videoUrl, setVideoUrl] = useState<string | null>(null);
  let [publishStatus, setPublishStatus] = useState<any>(null);
  let [userList, setUserList] = useState<any[]>([]);
  let [openShare, setOpenShare] = useState(false);
  let [message, setMessage] = useState<any>(null);
  let [selectedIndex, setSelectedIndex] = useState<number|null>(null);
  let [videoProgress, setVideoProgress] = useState(0);
  let videoRef = useRef<HTMLVideoElement>(null);
  

  useEffect(() => {
    props.onEvent({message: "GetVideoList", params: {setVideoList}});
    props.onEvent({message: "GetClientUser", params: {}});
  }, []);

  useEffect(() => {
    if (videoList.length > 0) {
      setSelectedVideo(videoList[0]);
    }
  }, [videoList]);

  useEffect(() => {
    if (!props.clientUserDict || !props.clientContactDict) return;
    let combinedList = Array.from(
      new Set(
        Array.from(props.clientUserDict.keys())
        .concat(Array.from(props.clientContactDict.keys())))
    );
    setUserList(combinedList.map((id: string) => (
      {
        id, 
        login: props.clientUserDict.has(id), 
        detail: props.clientUserDict.get(id),
        contact: props.clientContactDict.get(id) || null
      }
    )));
  }, [props.clientUserDict, props.clientContactDict]);

  useEffect(() => {
    if (!selectedVideo || !selectedVideo.id) return;
    setVideoProgress(0);
    props.onEvent({message: "GetVideoById", params: {selectedVideo, setVideoUrl}});
  }, [selectedVideo]);

  return(
    <div style={{height: "92vh"}}>
      {!props.forClient && <div style={{backgroundColor: "lightgreen"}}>[Add Video]</div>}
      <div style={{display: "flex", height: "100%"}}>
        <div style={{width: "250px", borderRight: "solid grey 1px"}}>
          {videoList
          .sort((a: any, b: any) => a?.name < b?.name ? -1 : 1)
          .map((video: any, index: number) => (
            <div 
              key={index}
              style={{
                padding: "5px",
                cursor: "pointer",
                backgroundColor: selectedVideo?.id === video.id ? "#eeeeee" : "white"
              }}
              onClick={(e: any) => setSelectedVideo(video)}>
              {video.name}
            </div>
          ))}
        </div>
        <div style={{flex: 1}}>
          {!props.forClient &&
          <div 
            style={{
              display: "flex", borderBottom: "solid grey 1px", 
              justifyContent:"space-between"
            }}>
            <span>Version</span>
            <div style={{flex: 1}}></div>
            <input 
              disabled
              type="file"
              onClick={(e: any) => e.target.value = null}
              onChange={async (e: any) => {
                if (e.target?.files?.[0]) {
                  setPublishStatus({status: "reading", messages: ["Reading file..."]})
                  let file = e.target.files[0];
                  const data = await new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                      resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(file);
                  });
                  props.onEvent({
                    message: "PublishVideo",
                    params: {
                      video: selectedVideo, 
                      videoData: data, 
                      filename: file.name, 
                      videoList,
                      setVideoList,
                      setVideoUrl, 
                      setPublishStatus
                    }
                  });
                  setPublishStatus(null);
                }
              }}
            />
            {props.vdedit &&
            <span style={{cursor: "pointer"}} onClick={() => setOpenShare(!openShare)}>[Sharing]</span>}
          </div>}
          {videoUrl &&
          <div style={{position: "relative"}}>
            <video 
              ref={videoRef}
              src={videoUrl} 
              onContextMenu={(e: any) => {
                e.preventDefault();
                return false
              }}
              onTimeUpdate={(e: any) => {
                if (!videoRef.current) return;
                setVideoProgress(100 * (videoRef.current.currentTime / videoRef.current.duration))
              }}
              // controls 
              width="900" height="450" 
              preload="metadata"
            />
            <div style={{width: "100%", backgroundColor: "lightgrey"}}>
              <div style={{backgroundColor: "red", width: `${videoProgress}%`, height: "5px"}}>
              </div>
            </div>
            <div>
              <button onClick={(e: any) => videoRef.current?.play()}>Play</button>
              <button onClick={(e: any) => videoRef.current?.pause()}>Pause</button>
              <button 
                onClick={(e: any) => {
                  if (!videoRef.current) return;
                  videoRef.current?.pause();
                  videoRef.current.currentTime = 0;
                }}>
                Back to Start
              </button>
              <button 
                onClick={(e: any) => {
                  if (!videoRef.current) return;
                  if (videoRef.current.currentTime > 10)
                    videoRef.current.currentTime -= 10;
                  else 
                    videoRef.current.currentTime = 0;
                }}>
                &lt;&lt;
              </button>
              <button 
                onClick={(e: any) => {
                  if (!videoRef.current) return;
                  if (videoRef.current.currentTime < videoRef.current.duration - 10)
                    videoRef.current.currentTime += 10;
                  else 
                    videoRef.current.currentTime = videoRef.current.duration;
                }}>
                &gt;&gt;
              </button>
            </div>
            {openShare &&
            <div 
              style={{
                position: "absolute", top: 0, right: 0, border: "solid grey 1px",
                height: "600px", width: "600px", overflow: "scroll", backgroundColor: "white",
                display: "grid", gridTemplateColumns: "1fr 2fr"
              }}>
              {userList
              .sort((a: any, b: any) => a.id < b.id ? -1 : 1)
              .flatMap((user: any, index: number) => {
                let {name, organization, role} = user?.detail;;
                return ([
                  <div key={index * 3 + 1} style={{borderBottom: "solid #cccccc 1px", display: "flex"}}>
                    <Checkbox 
                      key={index * 3} style={{marginRight: "5px"}} 
                      checked={(user?.contact?.videoList || []).includes(selectedVideo.id)}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        if (message) return;
                        setSelectedIndex(index)
                        props.onEvent({message: "SetClientContactPermission", params: {
                          email: user.id,
                          data: {
                            videoList: (user?.contact?.videoList || []).includes(selectedVideo.id) ?
                                      (user?.contact?.videoList || []).filter((videoId: any) => videoId !== selectedVideo.id)
                                      : Array.from(new Set((user?.contact?.videoList || []).concat([selectedVideo.id])))
                          },
                          setMessage,
                          videoList: videoList,
                        }});
                      }}
                    />
                    <div>{user.id}</div>
                  </div>,
                  <div key={index * 3 + 2} style={{borderBottom: "solid #cccccc 1px"}}>
                    <div>{`${name || "(blank)"} / ${organization || "(blank)"} / ${role || "(blank)"}`}</div>
                    {selectedIndex === index && message && 
                    <div style={{backgroundColor: "lightyellow"}}>
                      {message.map((item: string, messageIndex: number) => 
                      <div key={messageIndex} style={{color: "red"}}>{item}</div>)}
                    </div>}
                  </div>
                ])
              })}
            </div>}
          </div>}
        </div>
      </div>
      <Modal
        size="small"
        open={publishStatus !== null}>
        <div 
          style={{
            minHeight: "300px", overflowY: "auto", textAlign: "center", padding: "20px", 
            display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"
          }}>
          {(publishStatus?.messages || [])
          .map((message: string, index: number) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      </Modal>
    </div>
  )
}
