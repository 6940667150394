import React, { createRef, useEffect, useRef, useState } from "react";
import { getStepSection } from "./AlgorithmDiagram";
import { DiagramGroup, StepSection } from "./TypeDef";
import { Icon } from "semantic-ui-react";
// import { DownloadDataAsJsonFile } from "ismor-lib/utils/File";
import XDDiagramWorker from "./XDDiagram.worker";
import { compareSectionLabel } from "./AlgorithmDiagram";
import html2canvas from "html2canvas";
import moment from "moment";
import { convertToDoc } from "./SpecRender";
import { Document, Packer, AlignmentType } from "docx";
import sizeOf from "buffer-image-size";

// import init, { run, xdToDiagram } from "wasm-lib";
// let testwasm = async (data: any, setStepGroups: any, setNewData: any) => {
//   console.time("wasm");
//   await init();
//   let diagramGroupList = JSON.parse(xdToDiagram(JSON.stringify(data)));
//   setStepGroups(diagramGroupList);
//   setNewData(false);
//   console.timeEnd("wasm");
//   console.log(diagramGroupList);
// }

export default function XDDiagram(props: any) {
  const [xdId, setXdId] = useState<string|null>(null);
  const [newData, setNewData] = useState(true);
  const [stepGroups, setStepGroups] = useState<DiagramGroup[]>([]);
  const [stepSection, setStepSection] = useState<StepSection>({});
  const [edgeCount, setEdgeCount] = useState(0);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [compact, setCompact] = useState(false);
  const [waitMessage, setWaitMessage] = useState("Preparing Data");

  const worker = useRef<Worker>();
  const groupRef = useRef<React.RefObject<HTMLDivElement>[]>([]);

  useEffect(() => {
    if (!worker.current) {
      worker.current = new XDDiagramWorker();
      worker.current.onmessage = async (event: any) => {
        if (event.data.type === "progress") {
          setWaitMessage(`Preparing data: ${event.data.data}`);
          setWaitMessage(`Preparing data`)
        } else if (event.data.type === "result") {
          let diagramGroupList = JSON.parse(event.data.data);
          groupRef.current = [];
          for (var i = 0; i< diagramGroupList.length; i++) {
            let newRef = createRef<HTMLDivElement>();
            groupRef.current.push(newRef);
          }
          setStepGroups(diagramGroupList);
          setNewData(false);
        } else if (event.data.type === "resultwasm") {
          console.log("resultwasm");
          let diagramGroupList = JSON.parse(event.data.data);
          setStepGroups(diagramGroupList);
          setNewData(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (xdId !== props.xdId) {
      setXdId(props.xdId);
      setNewData(true);
    }
  }, [props.xdId]);

  useEffect(() => {
    if ((newData || props.xddata?.edges?.length !== edgeCount)
        && props.xddata?.edges?.length > 0) {
      prepareData();
    }
  }, [props.xddata]);

  useEffect(() => {
     if (selectedStepId === null)
      setCompact(false)
  }, [selectedStepId]);

  const prepareData = async () => {
    // DownloadDataAsJsonFile(props.xddata, "xddata");
    // console.log(props.xddata);
    // stepSection ------------------------------------------------------------------------
    setEdgeCount(props.xddata?.edges?.length);
    const stepSection_ = getStepSection(props.xddata)
    setStepSection(stepSection_);
    
    // stepGroups ------------------------------------------------------------------------
    if (worker.current) {
      // DownloadDataAsJsonFile(props.xddata, "xddata");
      worker.current.postMessage(JSON.stringify(props.xddata));
      // testwasm(props.xddata, setStepGroups, setNewData);
    }
  }

  const handleDiagramDownload = async () => {
    if (!groupRef.current) return;

    // Get images
    var i = 0;
    let imageDict: {[key: string]: {data: ArrayBuffer, imageSize: any}} = {};
    for (const ref of Object.values(groupRef.current)) {
      if (!ref.current) continue;
      i += 1;
      const canvas = await html2canvas(ref.current, {
        scrollX: 0,
        scrollY: 0,
        windowWidth: ref.current.scrollWidth,
        windowHeight: ref.current.scrollHeight,
      });
      let img: ArrayBuffer = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty or couldn't be converted to a Blob."));
            return;
          }
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as ArrayBuffer);
          reader.onerror = reject;
          reader.readAsArrayBuffer(blob);
        }, "image/png");
      });
      imageDict[i] = { data: img, imageSize: sizeOf(Buffer.from(img)) };
    }
    
    // Create template
    const docTemplate = {
      type: "Document",
      sections: [{ 
        properties: {},
        children: [{
          type: "Paragraph", indent: {left: "0pt"}, alignment: AlignmentType.CENTER, 
          children: Object.keys(imageDict).map((key: string) => (
            { type: "ImageRun", key: key, width: 600 }))
        }]
      }]
    }

    // Convert to doc
    let doc = convertToDoc(docTemplate, imageDict) as Document;  
  
    // Download
    const b64string = await Packer.toBase64String(doc);
    const link = document.createElement('a');
    const now = moment().format("YYYY-MM-DDTHH:mm:ss");
    link.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + b64string;
    link.download = `${props.xddata.name} Diagram ${now} ${i}.docx`;
    link.click();
    link.remove()
  }

  return(
    <div style={{height: "100%", padding: "5px", position: "relative"}}>
      <div
        style={{
          position: "absolute", top: 0, right: 0,
          backgroundColor: "green", 
          color: "white", 
          padding: "0 5px 0 5px", 
          margin: "0 1px 0 1px",
          cursor: "pointer",
          borderRadius: "3px",
        }}
        onClick={handleDiagramDownload}>
        <Icon name="picture" />
      </div>
      {newData ?
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        {`${waitMessage}`}
      </div>
      :
      <div style={{display: "flex", flexDirection: "column"}}>
        {stepGroups
        .sort((a: any, b: any) => compareSectionLabel(stepSection, a.roots[0], b.roots[0]))
        .map((group: DiagramGroup, groupIndex: number) => 
          <div 
            ref={groupRef.current[groupIndex]}
            style={{borderBottom: "solid green 2px"}}
            key={groupIndex}>
            <XdDiagramGroup
              num={stepGroups.length}
              groupIndex={groupIndex}
              groupLength={stepGroups.length}
              group={group}
              stepSection={stepSection}
              steps={props.xddata.steps}
              selectedStepId={selectedStepId}
              setSelectedStepId={setSelectedStepId}
              compact={compact}
              setCompact={setCompact}
            />
          </div>
        )}
      </div>
      }
    </div>
  )
}

function XdDiagramGroup(
  props: {
    num: number,
    group: DiagramGroup,
    groupIndex: number,
    groupLength: number,
    stepSection: StepSection,
    steps: any,
    selectedStepId: string | null,
    setSelectedStepId: any,
    compact: boolean,
    setCompact: any,
  }
) {
  return (
    <div
      style={{
        display: "flex", flex: 1, height: "100%",
        marginTop: props.groupIndex === 0 ? "0" : "5px",
        marginBottom: props.groupIndex === (props.groupLength -1) ? "0" : "5px",
      }}>
      <div style={{display: "flex", flexDirection: "column"}}>
        {props.group.roots.map((stepId: string, stepIndex: number) => {
          if (stepId.includes("origin")) {
            return (
              <div 
                key={stepIndex}
                style={{
                  cursor: "pointer", border: "solid grey 1px", 
                  textAlign: "center", borderRadius: "10px",
                  padding: "2px", backgroundColor: "cyan", flex: 1
                }}>
                {props.num - parseInt(stepId.split(":")[1])}
              </div>)
          }
          let downStream = props.selectedStepId && 
                          (props.steps?.[props.selectedStepId]?.dependOn || [])
                          .concat(props.steps?.[props.selectedStepId]?.dependOnOptional)
                          .includes(stepId);
          let upstream =  props.selectedStepId && 
                          (props.steps?.[stepId]?.dependOn || [])
                          .concat(props.steps?.[stepId]?.dependOnOptional || [])
                          .includes(props.selectedStepId)
          return(
            <div 
              key={stepIndex} 
              style={{
                display: props.selectedStepId == null ? "flex" 
                          : (props.selectedStepId === stepId 
                            || downStream || upstream) ? "flex"
                          : !props.compact ? "flex": "none",
                flexDirection: "column",
                justifyContent: "flex-start",
                flex: 1, cursor: "pointer",
                border: props.group.cycle.includes(stepId) ? "solid red 1px" : "solid grey 1px", 
                textAlign: "center", borderRadius: "10px",
                padding: "2px",
                backgroundColor: 
                  stepId === props.selectedStepId ? "lightgreen" 
                  : downStream && upstream ? "orange"
                  : downStream ? "pink"
                  : upstream ? "lightblue"
                  : "lightyellow"
              }}
              onClick={(e: any) => 
                props.setSelectedStepId(stepId === props.selectedStepId ? null : stepId)
              }>
              <div>
                {`${props.stepSection[stepId]?.sectionLabel} ${props.steps[stepId]?.description}`}
              </div>
              {props.selectedStepId === stepId &&
              <div style={{marginLeft:"5px"}}>
                <Icon
                  name={props.compact ? "expand" : "compress"}
                  inverted color="red" fitted
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.setCompact(!props.compact)
                  }}
                />
              </div>}
            </div>)
        })}
      </div>
      <div 
        style={{ display: "flex", flexDirection: "column" }}>
        {props.group.groupList
        .sort((a: any, b: any) => compareSectionLabel(props.stepSection, a.roots[0], b.roots[0]))
        .map((group: DiagramGroup, groupIndex: number) => 
          <XdDiagramGroup
            key={groupIndex}
            num={props.num}
            group={group}
            groupIndex={groupIndex}
            groupLength={props.group.groupList.length}
            stepSection={props.stepSection}
            steps={props.steps}
            selectedStepId={props.selectedStepId}
            setSelectedStepId={props.setSelectedStepId}
            compact={props.compact}
            setCompact={props.setCompact}
          />)}
      </div>
    </div>
  )
}
