import Anime from "../Anime"

export async function textSystem(anime: Anime, dt: number) {
  let comp = anime.components;
  for (const entityId of anime.entities) {
    if (comp.elementType.get(entityId) !== "text") continue;
    let textDisplay = comp?.textDisplay?.get(entityId);
    if (Number.isInteger(textDisplay?.length) && textDisplay?.length !== undefined) {
      if (!(textDisplay?.lastChange) || (dt - textDisplay?.lastChange > 1)) {
        textDisplay.length = textDisplay.length + 1;
        textDisplay.lastChange = dt;
        comp.layout?.set(entityId, textDisplay);
      }
    }
  }
}
