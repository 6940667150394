import { ExchangeDiagramData, DiagramGroup, StepSection, RelatedStepMap } from "./TypeDef";

let xdToDiagramProgess = 0;
let sizeThreshold = 5;

export const xdToDiagram: (xddata: ExchangeDiagramData, postMessage: any) => DiagramGroup[] = 
  (xddata, postMessage) => 
{
  xdToDiagramProgess = 0;
  let stepSection_ = getStepSection(xddata);
  let stepIdList = Object.keys(xddata.steps)
    .filter((stepId: string) => 
      (stepId in stepSection_) && !stepSection_?.[stepId]?.inactive);
  console.time();
  let groupList = getGroupList(xddata, stepIdList, stepSection_, postMessage);
  console.timeEnd();
  let originGroup = { 
    size: Math.max(1, groupList.reduce((acc: any, cur: any) => acc + cur.size, 0)),
    depth: 1 + Math.max(...groupList.map((item: any) => item.depth)),
    roots: ["origin:Start"], 
    groupList, 
    cycle: [] 
  };
  // return [originGroup];
  let [trunk, splitted, indices] = simplify(originGroup, [] as DiagramGroup[], []);
  trunk.roots = [`origin:${splitted.length}`]
  return [trunk, ...splitted];
}

function simplify(
  group: DiagramGroup, splitted: DiagramGroup[], indices: number[]
): [DiagramGroup, DiagramGroup[], number[]] {
  if (!splitCondition(group)) {
    return [group, splitted, indices];
  }
  for (var i=group.groupList.length - 1; i >= 0; i--) {
    let [group_, splitted_, indices_] = simplify(group.groupList[i], splitted, indices);
    splitted = splitted_;
    indices = indices_;
    if (!splitCondition(group_))
      group.groupList[i] = group_;
    else {
      let nextIndex = indices.length;
      indices.push(nextIndex);
      group.groupList[i] = {
        size: 1,
        depth: 1,
        roots: [`origin:${nextIndex}`],
        groupList: [],
        cycle: []
      }
      splitted = [
        {
          size: Math.max(1, group_.size),
          depth: 1 + group_.depth,
          roots: [`origin:${nextIndex}`],
          groupList: [group_],
          cycle: []
        },
        ...splitted
      ];
    }
  }
  group.size = Math.max(
    group.roots.length, 
    group.groupList.reduce((acc: any, cur: any) => acc + cur.size, 0));
  group.depth = 1 + Math.max(...group.groupList.map((item: any) => item.depth));
  return [group, splitted, indices];
}

function splitCondition(group: DiagramGroup) {
  return group.size > sizeThreshold;
}

export const getStepSection = (xddata: ExchangeDiagramData) => {
  let stepSection_ = Object.fromEntries(
  xddata.sections
    .flatMap((section: {name: string, steps: string[], inactive?: boolean}, 
              sectionSeq: number) => (
      section.steps.map((stepId: string, stepSeq: number) => ([
        stepId,
        {
          stepId,
          sectionName: section.name,
          sectionSeq,
          stepSeq,
          sectionLabel: `${sectionSeq + 1}.${stepSeq + 1}`,
          inactive: section?.inactive || false
        }
      ]))
    ))
  );
  return stepSection_
}

function getGroupList(
  xddata: ExchangeDiagramData, stepIdList: string[], stepSection: any, postMessage: any
): DiagramGroup[] {
  xdToDiagramProgess += 1;
  postMessage({type: "progress", data: `${xdToDiagramProgess}`})
  if (stepIdList.length === 0) return [];
  let partitions: string[][] = [];
  let relatedStepMap = getRelatedStepMap(stepIdList, xddata.edges);
  for (const stepId of stepIdList) {
    // joinIndices includes a list of index in partitions that related steps are in
    let joinIndices: number[] = [];
    for (const relatedItem of relatedStepMap[stepId]) {
      let index = partitions.findIndex((stepList: string[]) => stepList.includes(relatedItem.id));
      if (index != -1 && !joinIndices.includes(index))
        joinIndices.push(index);
    }
    joinIndices = joinIndices.sort((a: number, b: number) => a - b);

    // We then join all groups in joinIndices together to form a connected graph
    if (joinIndices.length === 0) {
      partitions.push([stepId]);
    } else {
      let newGroupList: string[][] = [];
      for (var index = 0; index < partitions.length; index++) {
        if (!joinIndices.includes(index))
          newGroupList.push(partitions[index]);
        else if (index === joinIndices[0]) {
          newGroupList.push(partitions[index].concat([stepId]));
        } else {
          newGroupList[joinIndices[0]] = 
            newGroupList[joinIndices[0]].concat(partitions[index]);
        }
      }
      partitions = newGroupList;
    }
  }
  // Then sort by the number of steps within group
  partitions = partitions.sort((a: string[], b: string[]) => b.length - a.length);
  let diagramGroupList: DiagramGroup[] = partitions.map(
    (partition: string[]) => {
      if (partition.length === 0) {
        return {
          size: 0,
          depth: 0,
          roots: [],
          groupList: [],
          cycle: [],
        }
      }
      let [roots, children, cycle] = getSubgroup(partition, stepSection, relatedStepMap);
      if (roots.length === 0 && children.length > 0) {
        let groupList = children.length === 1 ? [] 
                        : getGroupList(xddata, children.slice(1), stepSection, postMessage)
        return {
          size: Math.max(1, groupList.reduce((acc: any, cur: any) => acc + cur.size, 0)),
          depth: 1 + Math.max(...groupList.map((item: any) => item.depth)),
          roots: [children[0]],
          groupList,
          cycle: children.slice(1)
                  .filter((relatedId: string) => 
                    (xddata.steps?.[relatedId]?.dependOn || [])
                    .concat((xddata.steps?.[relatedId]?.dependOnOptional || []))
                    .includes(children[0]))
                  .map((relatedId: string) => stepSection?.[relatedId]?.sectionLabel || "[No data]"),
        }
      } else {
        let groupList = children.length === 0 ? [] 
                        : getGroupList(xddata, children, stepSection, postMessage)
        return {
          size: Math.max(roots.length, groupList.reduce((acc: any, cur: any) => acc + cur.size, 0)),
          depth: children.length === 0 ? 1 : 1 + Math.max(...groupList.map((item: any) => item.depth)),
          roots: roots,
          groupList,
          cycle: cycle,
        }
      }
    });
  return diagramGroupList
}

const getSubgroup
  = (partition: string[], stepSection: StepSection[], relatedStepMap: RelatedStepMap) => 
{
  let removedStepIds: string[] = [];
  let roots: string[] = [];
  while (roots.length == 0 && removedStepIds.length < partition.length) {
    let remainingStepIds = partition
      .filter((stepId: string) => !removedStepIds.includes(stepId));
    roots = remainingStepIds
      .sort((a: any, b: any) => compareSectionLabel(stepSection, a as string, b as string))
      .filter((stepId: string) => {
        let fromStepList = relatedStepMap[stepId]
          .filter((relatedItem: {id: string, direction: string}) => 
            !removedStepIds.includes(relatedItem.id) && relatedItem.direction == "to"
          )
          ;
        return fromStepList.length === 0
    });
    if (roots.length === 0) {
      let stepIdToRemove = remainingStepIds
        .flatMap((remainingStepId: string) => 
          relatedStepMap[remainingStepId]
            .filter((relatedStep: any) => 
              relatedStep.direction == "to" && !removedStepIds.includes(relatedStep.id))
            .map((relatedStep: any) => ({fromId: relatedStep.id, toId: remainingStepId})))
        .sort((a: any, b: any) => compareSectionLabel(stepSection, a.toId as string, b.toId as string))
        [0].fromId;
      removedStepIds.push(stepIdToRemove);  
    }
  }
  let cycle = Array.from(new Set(removedStepIds
    .flatMap((removedStepId: string) =>
      relatedStepMap[removedStepId]
        .filter((item: any) => item.direction == "from")
        .map((item: any) => item.id as string)
    )));
  roots = roots.sort((a: any, b: any) => compareSectionLabel(stepSection, a as string, b as string));
  return [
    roots, 
    partition
      .filter((stepId: string) => !roots.includes(stepId))
      .sort((a: any, b: any) => compareSectionLabel(stepSection, a as string, b as string)),
    cycle
  ];
}

// relatedStep map each stepId to steps related to it
const getRelatedStepMap = (stepIdList: string[], edgeList: any[]) => {
  return Object.fromEntries(stepIdList.map((stepId: string) => ([
    stepId, 
    edgeList
      .filter((edge: any) => 
        (edge.to === stepId && stepIdList.includes(edge.from))
        || 
        (edge.from === stepId && stepIdList.includes(edge.to))
        )
      .map((edge: any) => 
        edge.to === stepId 
        ? { id: edge.from, direction: "to", original: edge }
        : { id: edge.to, direction: "from", original: edge })
  ])));
}

export function compareSectionLabel(stepSection: any, a: string, b: string): number {
  let [a1, a2] = stepSection[a]?.sectionLabel?.split(".")?.map((i: string) => Number(i) || 0) || [0, 0];
  let [b1, b2] = stepSection[b]?.sectionLabel?.split(".")?.map((i: string) => Number(i) || 0) || [0, 0];
  if (a1 < b1) return -1;
  if (a1 > b1) return 1;
  if (a2 < b2) return -1;
  if (a2 > b2) return 1;
  return 0;
}