const stringify = require('json-stable-stringify')

export type ScreenData = {
    name?: string,
    project?: string,
    height?: number,
    width?: number,
    elements?: Element[],
    screenPropsDefault?: ScreenPropsDefault,
    memo?: boolean,
    forwardRef?: boolean,
    isMounted?: boolean,
    // displayName?: string,
}

export type ScreenPropsDefault = { [key: string]: any }

export type Element = {
    id: number,
    name: string,
    label?: string,
    from: string | null,
    void: boolean,
    parent: number,
    seq: number,
    props?: { [key: string]: { type: string, value: string, valueEN?: string } },
}

export const renderJsx = (screenData: ScreenData) => {
    if (screenData.elements && Object.keys(screenData.elements).length > 0) {
        const importList = renderImport(screenData.elements)
        const render = renderElement(screenData.elements, 0, '', '      ')
        const screenPropsDefault = `export const screenPropsDefault = ${stringify(screenData.screenPropsDefault)}`
        const originalScreenData = `${stringify(screenData, { space: '  ' })}`

// Case isMounted =======================================================================
        const isMountedCode = screenData?.isMounted ? `

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

`       
// Case not isMounted ===================================================================
        : ""


        const displayNameCode = (screenData?.memo || screenData?.forwardRef) ? 
        `${screenData.name}.displayName = "${screenData.name}";` : "";

// Case forwardRef =====================================================================
        const code = screenData?.forwardRef ? 
        `import React from 'react';${importList}

const ${screenData.name} = React.forwardRef((props: any, ref: any) => {${isMountedCode}
    return(${render}
    )
})

${displayNameCode}
export default ${screenData.memo ? "React.memo(" : ""}${screenData.name}${screenData.memo ? ")" : ""}

${screenPropsDefault}

/* Date Time : ${Date()}
/* Original screen Data ************************************************************

${originalScreenData}

*********************************************************************************** */
` 
// Case not forwardRef ==================================================================
        : `import React from 'react';${importList}

const ${screenData.name} = (props: any) => {${isMountedCode}
    return(${render}
    )
}

${displayNameCode}
export default ${screenData.memo ? "React.memo(" : ""}${screenData.name}${screenData.memo ? ")" : ""}

${screenPropsDefault}

/* Date Time : ${Date()}
/* Original screen Data ************************************************************

${originalScreenData}

*********************************************************************************** */
`
// Finish cases =========================================================================

        return code
    } 
    
    return ''
}

const renderImport = (elements: Element[]) => {
    // console.log(elements)
    const importElements = elements.filter(element => (typeof element.from === "string"))
    let uniqueElements: Element[] = []
    for (const element of importElements) {
        const dups = uniqueElements.filter(uelem => (uelem.name === element.name && uelem.from === element.from))
        if (dups.length === 0) {
            uniqueElements.push(element)
        }
    }
    let importDict: { [key: string]: any } = {}
    for (const element of uniqueElements) {
        if (!Object.keys(importDict).includes(element.from!)) {
            importDict[element.from!] = []
        }
        importDict[element.from!].push(element)
    }
    // console.log(importDict)
    let output = "\nimport {IntlProvider, FormattedMessage, useIntl} from 'react-intl';\n"
    for (const key of Object.keys(importDict)) {
        output +=   `\nimport {\n${importDict[key].map((item: any) => "  " + item.name).join(",\n")}\n} ` +
                    `from '${importDict[key][0].from}'`
    }
    return output
}

const renderElement = 
    (elements: Element[], id: number, output_: string, indent: string) => {
    let output = output_
    const node = elements.filter((elem: any) => elem.id ===id)[0]
    let props = ""
    if (node.props) {
        props = Object.keys(node.props)
            .filter(name => {
                return node.props![name].value.trim() !== ""
            })
            .sort((a, b)=> { return (a < b) ? -1 : 1 })
            .map(name => {
                let renderValue: string;
                if (typeof node.props?.[name]?.valueEN === "string") {
                    renderValue = `{props.languageUX === "en" ? ` + 
                                  `"${node.props?.[name]?.valueEN}" : ` + 
                                  `"${node.props?.[name]?.value}"}`
                } else {
                    renderValue = `"${node.props?.[name]?.value || ""}"`;
                }
                return(
                    name === "children" ? '' :
                        node.props![name] && node.props![name].type === "value" ?
                            `\n${indent + spacer}${name}=${renderValue}` :
                            `\n${indent + spacer}${name}={${node.props![name].value}}`
                    )
            }).join('')
    } 
    output += `\n${indent}<${node.name}${props}>`

    // Add children prop
    if (node.props && node.props.children) {
        let renderValue: string;
        // console.log(node.props?.children?.valueEN);
        // console.log(typeof node.props?.children?.valueEN)
        if (typeof node.props.children?.valueEN === "string") {
            renderValue = `{props.languageUX === "en" ? ` + 
                            `"${node.props.children?.valueEN}" : ` + 
                            `"${node.props.children?.value}"}`
        } else {
            renderValue = node.props.children?.value || "";
        }
        node.props.children.type === "value" ?
            output += `\n${indent + spacer}${renderValue}` :
            output += `\n${indent + spacer}{${node.props.children.value}}`
    }

    // Add children element
    const children = elements.filter((elem: any) => elem.parent === id)
        .sort((a, b) => (a.seq - b.seq))
    const newIndent = indent + spacer
    for (const child of children) {
        output = renderElement(elements, child.id, output, newIndent)
    }
    output += `\n${indent}</${node.name}>`
    return output
}

const spacer = '  '
